import React from "react";
import { makeStyles } from "@mui/styles";
import { Select as MuiSelect, MenuItem, InputLabel, FormControl } from "@mui/material";

const useSelectStyles = makeStyles(() => ({
  select: {
    height: "48px",
    padding: "0rem 1.5rem",
    border: "1px solid #EDEDED",
    borderRadius: "8px !important",
    "&>fieldset": {
      border: "none",
    },
    "&:hover": {
      border: "1px solid #777",
    },
    "&.MuiInputBase-root::before, .MuiInputBase-root:hover::before": {
      border: "none !important",
    },
    "&.MuiInputBase-root::after": {
      border: "none !important",
      transform: "scaleX(0) !important",
    },
  },
}));

export const Select = ({errormessage, ...props }) => {
  const classes = useSelectStyles();

  return (
    <div className="">
      {props?.label && (
        <label className="block text-base leading-[20.29px] text-gray-400 mb-[5px]">
          {props.label}
        </label>
      )}
      <FormControl>
        {props?.placeholder && (
          <InputLabel 
            id="demo-simple-select-standard-label" 
            sx={{
              color: "#CED0D3",
              fontSize: "16px",
              fontFamily: "Euclid Circular A"
            }}
          >
            Select
          </InputLabel>
        )}
        <MuiSelect className={classes.select} {...props}>
          {(props.options || []).map((option, idx) => {
            return (
              <MenuItem key={idx} value={option?.key}>
                {option?.value}
              </MenuItem>
            );
          })}
        </MuiSelect>
        {errormessage && <p className="error__msg mt-[1px]">{errormessage}</p>}
      </FormControl>
    </div>
  );
};
