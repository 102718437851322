import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    submitButton: {
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        width: '100% !important',
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important"
    },
}));

export default function Selectfilter(
    {
        filter,
        filteredDoctor,
        filteredDoctorTest,
        doctor,
        setDoctor,
        doctorTest,
        setTest,
        setDoctorTest,
        channel,
        setChannel,
        status,
        setStatus,
        handleSubmit,
        filteredTest,
        test
    }
) {
    const classes = useStyles();
    
    return (
        <form className='flex flex-col h-full' onSubmit={handleSubmit}>
            {filter === 'test' && (
                <>
                    <div>
                        <p className='text-gray-400  mb-1'>Test</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={test}
                                onChange={(e) => {setTest(e.target.value)}}
                            >
                                {filteredTest.map((f, index) => (
                                    <MenuItem key={index} value={f}>{f}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <p className='text-gray-400  mb-1'>Doctor</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={doctorTest}
                                onChange={(e) => setDoctorTest(e.target.value)}
                            >
                                {filteredDoctorTest.map((f) => (
                                    <MenuItem key={f._id} value={`${f.lastName} ${f.firstName}`}>{f.lastName} {f.firstName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </>
            )}
    
            {filter === 'consult' && (
                <>
                    <div>
                        <p className='text-gray-400  mb-1'>Doctor</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={doctor}
                                onChange={(e) => setDoctor(e.target.value)}
                            >
                                {filteredDoctor.map((f) => (
                                    <MenuItem key={f._id} value={`${f.lastName} ${f.firstName}`}>{f.lastName} {f.firstName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <p className='text-gray-400  mb-1'>Channel</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={channel}
                                onChange={(e) => setChannel(e.target.value)}
                            >
                                <MenuItem value="chat">Chat</MenuItem>
                                <MenuItem value="video">Video</MenuItem>
                                <MenuItem value="voice">Voice</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <p className='text-gray-400  mb-1'>Status</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value="ongoing">Ongoing</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="accepted">Accepted</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </>
            )}
            
            {filter === 'prescribe' && (
                <>
                    <div>
                        <p className='text-gray-400  mb-1'>Doctor</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={doctor}
                                // label="Heala"
                                onChange={(e) => setDoctor(e.target.value)}
                            >
                                <MenuItem value="John Doe">John Doe</MenuItem>
                                <MenuItem value="John Doe">John Doe</MenuItem>
                                <MenuItem value="John Doe">John Doe</MenuItem>
                                <MenuItem value="John Doe">John Doe</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <p className='text-gray-400  mb-1'>Status</p>
                        <FormControl sx={{ my: 1, minWidth: 120 }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={status}
                                // label="Heala"
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <MenuItem value="scheduled">Scheduled</MenuItem>
                                <MenuItem value="ongoing">Ongoing</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="accepted">Accepted</MenuItem>
                                <MenuItem value="completed">Completed</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                                <MenuItem value="rescheduled">Rescheduled</MenuItem>
                                <MenuItem value="missed">Missed</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </>
            )}
            
            <div className='flex flex-col items-center justify-end mb-10 flex-1 '>
                <Button 
                    type='submit'
                    variant='contained' 
                    className={classes.submitButton}
                >
                    Apply Filter
                </Button>
            </div>
        </form>
    )
}
