export const formInitialValuesProd = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: null,
  gender: "",
  doctor: "",
  symptoms: [],
  description: "",
  firstNotice: "",
  time: "",
  providerId: "",
  contactMedium: "",
  discomfortLevel: "",
};

export const formInitialValuesLocal = {
  firstName: "Duste",
  lastName: "Manta",
  email: "dms@gmail.com",
  phoneNumber: "09023456789",
  gender: "male",
  doctor: "",
  symptoms: [{name: "Constipation"}, {name: "Abortion"}],
  fakeSymptoms: [{ __typename: "Symptoms", ID: "53", Name: "Constipation" },  { ID: "170", Name: "Abortion", __typename: "Symptoms" }],
  description: "This is a test consultation.",
  firstNotice: "Last week",
  time: "",
  providerId: "",
  contactMedium: "voice",
  discomfortLevel: "mild"
};

export const hourFormat = Object.freeze({
    "1":1,
    "2":2,
    "3":3,
    "4":4,
    "5":5,
    "6":6,
    "7":7,
    "8":8,
    "9":9,
    "10":10,
    "11":11,
    "12":12,
    "13":1,
    "14":2,
    "15":3,
    "16":4,
    "17":5,
    "18":6,
    "19":7,
    "20":8,
    "21":9,
    "22":10,
    "23":11,
    "00":12,
})
