import { Skeleton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react'

const SkeletonLoader = ({ rowsNum, tableHead }) => {
    return (
        <TableContainer component={Paper} sx={{borderRadius:"8px"}}>
            <Table>
                <TableHead sx={{bgcolor:"#3e5ea9"}}>
                    <TableRow>
                        {Array.from({ length: tableHead }).map((_, index) =>
                            <TableCell key={index}>
                                <Skeleton animation="wave" variant="text" />
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from({ length: rowsNum }).map((_, index) => (
                        <TableRow key={index}>
                            {Array.from({ length: tableHead }).map((_, index2) => (
                                <TableCell key={index2} component="th" scope="row">
                                    <Skeleton animation="wave" variant="text" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default SkeletonLoader;