import { createContext, useState } from "react";

export const PrescriptionContext = createContext();

export const PrescriptionProvider = ({children}) => {
    const [prescription, setPrescription] = useState(null);
        
    const fetchPrescription = () => {
        const storedPrescription = sessionStorage.getItem("prescription");
        if (storedPrescription !== undefined && storedPrescription !== null && storedPrescription !== "undefined") {
            try {
                const parsedPrescription = JSON.parse(storedPrescription);
                setPrescription(parsedPrescription.getPrescriptions);
            } catch (error) {
                console.error("Error parsing stored Prescription:", error);
            }
        } else {
            setPrescription(null);
        }  
    }

    return (
        <PrescriptionContext.Provider value={{prescription, fetchPrescription}}>
            {children}
        </PrescriptionContext.Provider>
    )
}