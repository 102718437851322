import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Chip } from "@mui/material";
import { GET_ISSUES, GET_SERVER_TIME } from "../../api/graphQL/query";
import { useFetchAPI } from "../../api/axios/axios";

import useAlert from "../../hooks/useAlert";
import { getAvailableTimeSlot } from "../helperFunctions";

let uniqueProvider =
  process.env.REACT_APP_PROVIDER_KEY_Khairo === "66e000f0a983160013ca8d38"
    ? true
    : false;

const useStyles = makeStyles(() => ({
  cancelButton: {
    padding: "16px 24px !important",
    borderRadius: "8px !important",
    textTransform: "capitalize !important",
    borderColor: "#3E5EA9 !important",
    color: "#3E5EA9 !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    fontFamily: "Euclid Circular A !important",
    width: "100%",

    "@media (max-width: 400px)": {
      padding: "16px 14px !important",
    },
  },
  submitButton: {
    padding: "18px 24px !important",
    borderRadius: "8px !important",
    textTransform: "capitalize !important",
    backgroundColor: "#3E5EA9 !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    fontFamily: "Euclid Circular A !important",
    width: "100%",

    "@media (max-width: 400px)": {
      padding: "16px 14px !important",
    },
  },
}));

export default function SelectLabels({
  handleDrawerClose,
  setConfirmDrawer,
  confirmDrawer,
  time,
  setTime,
  channel,
  setChannel,
  symptoms,
  setSymptoms,
  level,
  setLevel,
  expect,
  setExpect,
  aob,
  setAob,
  handleReset,
  doc,
  selectedDate,
  setFee,
  batches,
  setBatches,
  age,
  setAge,
  weight,
  setWeight,
  height,
  setHeight,
}) {
  const classes = useStyles();
  const { displayAlert } = useAlert();
  const { error, data, loading: issuesLoading } = useQuery(GET_ISSUES);
  const issues = data?.getIssues?.issues;
  const [plansLoading, setPlansLoading] = useState(false);
  const [plansError, setPlansError] = useState(null);
  const [plansData, setPlansData] = useState([]);
  const [required, setRequired] = useState(false);
  const [timeOptions, setTimeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [plan, setPlan] = useState([]);
  const apiKey = localStorage.getItem("apiKey");
  const authToken = localStorage.getItem("authToken");
  const prov = JSON.parse(localStorage.getItem("profile"));
  const providerId = localStorage.getItem("providerId");
  const [docData, setDocData] = useState([]);

 

  const {
    error: serErr,
    data: serData,
    loading: serLoad,
  } = useQuery(GET_SERVER_TIME);
  const [doctorLoading, setDoctorLoading] = useState(false);
  const [doctorError, setDoctorError] = useState(null);
  const { GET_DOCTOR_AVAILIBILITY_FOR_DATE, GET_PLANS } = useFetchAPI();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setPlansLoading(true);
        const response = await GET_PLANS(providerId, apiKey);
        setPlansData(response.data);
        setPlan(response.data);
      } catch (error) {
        setPlansError(error);
      } finally {
        setPlansLoading(false);
      }
    };

    fetchPlans();
  }, [providerId, authToken]);

  useEffect(() => {
    let isMounted = true;

    const fetchTimeOptions = async () => {
      try {
        setDoctorLoading(true);
        if (!doc?._id) return; // Skip if doctor ID is not available

        // Make the API call to fetch doctor availability for the selected date
        const response = await GET_DOCTOR_AVAILIBILITY_FOR_DATE(
          selectedDate,
          doc._id
        );

        // Extract data from the response
        const responseData = response.data;

        // Process the data as needed
        const times = responseData.data.times;

        // Set state only if the component is still mounted
        if (isMounted) {
          setDocData(responseData);
          setTimeOptions(times);
        }
      } catch (error) {
        // Handle error if fetching doctor availability fails
        console.error("Error fetching doctor availability:", error);
        setDoctorError(error);
      } finally {
        setDoctorLoading(false);
      }
    };

    if (selectedDate && doc._id) {
      fetchTimeOptions();
    }

    // Cleanup function to prevent setting state on an unmounted component
    return () => {
      isMounted = false;
    };
  }, [doc._id, selectedDate]);

  const getServerTime = serData?.getServerTime?.timeLagosJsDate;

  var inputDate = selectedDate;
  var dateComponents = inputDate.split("-");

  var year = dateComponents[0];
  var month = parseInt(dateComponents[1]) - 1; // JavaScript uses zero-based months
  var day = dateComponents[2];

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var dateObj = new Date(year, month, day);
  var formattedDate =
    dateObj.toString().substr(0, 3) +
    " " +
    months[month] +
    " " +
    day +
    " " +
    year;

  const currentDateL = getServerTime?.split("").splice(0, 15).join("");
  const currentDate = getServerTime;

  console.log(currentDateL, "currentDatexyz", formattedDate, currentDate);

  const selectedSlot =
    currentDateL == formattedDate
      ? getAvailableTimeSlot(timeOptions, currentDate)
      : timeOptions;

  const handleChipDelete = (chipToDelete) => () => {
    setBatches(batches.filter((chip) => chip !== chipToDelete));
  };

  const handleSymptomChange = (event) => {
    const value = event.target.value.trim();
    if (value === "") {
      setSymptoms("");
      setFilteredIssues([]);
      return;
    }

    setSymptoms(value);

    const symptomStringCase =
      value[0].toUpperCase() + value.slice(1).toLowerCase();
    setSymptoms(symptomStringCase);

    const filtered = issues.filter((issue) =>
      issue.Name.includes(symptomStringCase)
    );
    setFilteredIssues(filtered);
    // setBatchesError('');
  };

  const handleSymptomClick = (item) => {
    setSymptoms(item.Name);
    setFilteredIssues([]);
    setSymptoms("");
    setBatches((batches) => [item.Name, ...batches]);
  };

  const handleSymptomClick1 = (item) => {
    setSymptoms(item);
    setFilteredIssues([]);
    setSymptoms("");
    setBatches((batches) => [item, ...batches]);
  };

  const handleBatchRemove = (id) => {
    setBatches((batches) => [...batches.filter((_, idx) => idx !== id)]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (uniqueProvider){
        getFee();

    }else{
        if (!time) {
            displayAlert("error", "Choose an available time slot");
            return;
          } else if (!channel) {
            displayAlert("error", "Select a contact medium");
            return;
          } else if (!expect) {
            displayAlert("error", "Select a first notice period");
            setRequired(true);
            return;
          } else if (!level) {
            displayAlert("error", "Select a discomfort level");
            return;
          } else if (batches.length === 0 && !symptoms) {
            displayAlert("error", "Select at least one symptom");
            return;
          } else {
            getFee();
          }

    }
   
  };

  const getFee = () => {
    setLoading(true);
    try {
      setFee(plan[0]?.amount);
      setConfirmDrawer(!confirmDrawer);
      setLoading(false);
    } catch (error) {
      console.error("Error from getFee function:", error);
    }
  };
  let key = localStorage.getItem("iskey");

  return (
    <form onSubmit={handleSubmit} className="h-full flex flex-col">
      <h3 className="font-medium text-[25px]">Booking Preference</h3>
      <p className="font-normal text-[16px]">
        Pick a suitable time and communication channel for your consultation
      </p>
      <div className="mt-6">
        <div>
          <p className="font-normal text-[16px] text-gray-400 mb-1">Time</p>
          <FormControl sx={{ my: 1, minWidth: 120 }}>
            {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={time}
              // label="Heala"
              onChange={(e) => setTime(e.target.value)}
            >
              {selectedSlot?.length > 0 ? (
                selectedSlot.map((t) => (
                  <MenuItem key={t._id} value={t.start}>
                    {t.start} (GMT+1)
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No available slot</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="mt-8">
        {uniqueProvider ? (
          <>
            <h3 className="font-medium text-[25px]">Additional Information</h3>

            <div className="mt-6 flex flex-col gap-6">
              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  How old are you?
                </p>
                <div>
                  <div className="chip-input-container">
                    <div className="chips-input font-euclid">
                      <TextField
                        onChange={(e) => setAge(e.target.value)}
                        variant="standard"
                        value={age}
                        type="number"
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </div>
                </div>
                {/* {batchesError && <p className='text-error'>{batchesError}</p>} */}
              </div>
              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  Weight
                </p>
                <div>
                  <div className="chip-input-container">
                    <div className="chips-input font-euclid">
                      <TextField
                        onChange={(e) => setWeight(e.target.value)}
                        variant="standard"
                        value={weight}
                        fullWidth
                        type="number"

                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </div>
                </div>
                {/* {batchesError && <p className='text-error'>{batchesError}</p>} */}
              </div>
              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  Height
                </p>
                <div>
                  <div className="chip-input-container">
                    <div className="chips-input font-euclid">
                      <TextField
                        onChange={(e) => setHeight(e.target.value)}
                        variant="standard"
                        value={height}
                        fullWidth
                        type="number"

                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </div>
                </div>
                {/* {batchesError && <p className='text-error'>{batchesError}</p>} */}
              </div>

              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  Communication Channel
                </p>
                <FormControl sx={{ my: 1, minWidth: 120 }}>
                  {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                  <Select
                    className={classes.error}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={channel}
                    // label="Heala"
                    onChange={(e) => setChannel(e.target.value)}
                  >
                    <MenuItem value="chat">Chat</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="voice">Voice</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 className="font-medium text-[25px]">Symptom Information</h3>
            <p className="font-normal text-[16px]">
              Tell us what the problem is
            </p>

            <div className="mt-6 flex flex-col gap-6">
              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  What are your symptoms?
                </p>
                <div>
                  <div className="chip-input-container">
                    <div className="chips-input font-euclid">
                      {batches.map((chip, index) => (
                        <Chip
                          key={index}
                          label={chip}
                          onDelete={handleChipDelete(chip)}
                          className="chip"
                        />
                      ))}
                      <TextField
                        onChange={handleSymptomChange}
                        variant="standard"
                        value={symptoms}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            setSymptoms(symptoms);
                            setFilteredIssues([]);
                            handleSymptomClick1(e.target.value);
                          }
                        }}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                      />
                    </div>
                  </div>
                  {filteredIssues.length > 0 && (
                    <div
                      className="heela-symptoms-container absolute z-[9999]"
                      style={{ padding: "1rem" }}
                    >
                      {filteredIssues.map((item) => (
                        <div
                          key={item.ID}
                          className="heela-symptoms-option"
                          onClick={() => handleSymptomClick(item)}
                        >
                          {item.Name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* {batchesError && <p className='text-error'>{batchesError}</p>} */}
              </div>

              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  What is your discomfort level?
                </p>
                <FormControl sx={{ my: 1, minWidth: 120 }}>
                  {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={level}
                    // label="Heala"
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="mild">Mild</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="intense">Intense</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  When did you start experiencing the symptoms?
                </p>
                {required ? (
                  <FormControl sx={{ my: 1, minWidth: 120 }} error>
                    {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={expect}
                      // label="Heala"
                      onChange={(e) => setExpect(e.target.value)}
                    >
                      <MenuItem value="this week">This week</MenuItem>
                      <MenuItem value="last week">Last week</MenuItem>
                      <MenuItem value="one month ago">One month ago</MenuItem>
                    </Select>
                    <FormHelperText>This field is required!</FormHelperText>
                  </FormControl>
                ) : (
                  <FormControl sx={{ my: 1, minWidth: 120 }}>
                    {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={expect}
                      // label="Heala"
                      onChange={(e) => {
                        setRequired(false);
                        setExpect(e.target.value);
                      }}
                    >
                      <MenuItem value="this week">This week</MenuItem>
                      <MenuItem value="last week">Last week</MenuItem>
                      <MenuItem value="one month ago">One month ago</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </div>

              <div>
                <p className="font-normal text-[16px] text-gray-400 mb-1">
                  Communication Channel
                </p>
                <FormControl sx={{ my: 1, minWidth: 120 }}>
                  {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                  <Select
                    className={classes.error}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={channel}
                    // label="Heala"
                    onChange={(e) => setChannel(e.target.value)}
                  >
                    <MenuItem value="chat">Chat</MenuItem>
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="voice">Voice</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex-1 w-full flex items-end mb-10 gap-4">
        <Button
          variant="outlined"
          onClick={() => {
            handleReset();
            handleDrawerClose();
          }}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          className={classes.submitButton}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 20, height: 20, color: "#fff" }}
            />
          ) : key ? (
            "Continue "
          ) : (
            "Book Appointment"
          )}
        </Button>
      </div>
    </form>
  );
}
