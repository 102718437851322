import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Input } from "../Input";
import { Button } from "../Button";
import { Select } from "../Select";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useMutation } from "@apollo/client";
import useAlert from "../../hooks/useAlert";
import { SIGNUP, CREATE_PROFILE, GET_USER_TYPES } from "../../api/graphQL/query";
import { PhoneDropdown } from "../phone";

export const SignUpForm = () => {
  const navigate = useNavigate();
  const { displayAlert } = useAlert();
  const user =  JSON.parse(localStorage.getItem('profile'));

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("userEmail"))

  const [passwordError, setPasswordError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const [signupMutation, { loading: signupLoading, error: signupError }] = useMutation(SIGNUP);
  const [createProfileMutation, { loading: createProfileLoading, error: createProfileError }] = useMutation(CREATE_PROFILE);
  const { loading: userTypeLoading, error: userTypeError, data } = useQuery(GET_USER_TYPES);
  
  const providerId = localStorage.getItem("providerId");

  console.log(user,'user')



  const userTypeId = data?.getUserTypes?.userType[1]?._id;

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };
  
  const handleRedirect = () => {
    window.open("https://heala.ng/terms/", "_blank");
  }  

  const handleChecked = () => {
    if (checkboxChecked) {
      handleSignup();
    } else {
      displayAlert('error', 'Please accept the terms and conditions to proceed.')
    }
  }

  const handleSignup = async () => {
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");
    setGenderError("");
    setPhoneError("");

    if (password !== confirmPassword) {
      setPasswordError("Passwords are not the same");
      return;
    }
    
    if (!firstName) {
      setFirstNameError("Please enter your first name.");
    }
    if (!lastName) {
      setLastNameError("Please enter your last name.");
    }
    if (!email) {
      setEmailError("Please enter your email address.");
    }
    if (!gender) {
      setGenderError("Please select your gender.");
    }
    if (!phone) {
      setPhoneError("Please enter your phone number.");
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !gender ||
      !phone
    ) {
      return;
    }
   
 
      
   let user =  { firstName: firstName,
    lastName: lastName,
    gender: gender,
    phoneNumber: phone,
  
   

  }
  localStorage.setItem('userEmail', email);

      localStorage.setItem('profile', JSON.stringify(user));

      
   
      setFirstName("");
      setLastName("");
      setGender("");
      setPhone("");
      navigate("/bookconsultation");

   
  };

  return (
    <div className="w-full">
      <h1 className="mid:block hidden font-semibold text-black text-[40px] leading-[50.72px] m-0">
        Get Started
      </h1>
      <h1 className="mid:hidden font-semibold text-black text-[40px] leading-[50.72px] m-0">
        Sign up
      </h1>
      <p className="mid:block hidden text-base leading-[20.29px] m-0 mt-2">
        Welcome to the Consultation Portal. Let’s get you paired with a doctor.
      </p>
      <p className="mid:hidden text-base leading-[20.29px] m-0 mt-2">
        Welcome to Heala. Let’s get you signed up
      </p>
      <form className="heala-form-auth mt-5">
        <div className="space-y-6 mb-[30px]">
       
          <div className="mid:flex mid:space-x-4 grid grid-cols-2 space-x-4">
            <Input
              type="text"
              label="First Name"
              placeholder="First Name"
              fullWidth
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              errormessage={firstNameError}
            />
            <Input 
              type="text" 
              label="Last Name" 
              placeholder="Last Name" 
              fullWidth 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)}
              errormessage={lastNameError}
            />
          </div>

          <div>
            <Input
              type="email"
              label="Email Address"
              placeholder="Email Address"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              errormessage={emailError}
            />
          </div>
       
          <div className="grid-cols-2 space-x-4">
            <PhoneDropdown country='ng' setPhone={setPhone} phone={phone} errormessage={phoneError} seterrormessage={setPhoneError}/>

          </div>
          <div className="grid-cols-2 space-x-4">
            <Select
              label="Gender"
              options={[
                { key: "male", value: "Male" },
                { key: "female", value: "Female" },
              ]}
              fullWidth
              value={gender}
              placeholder="Gender"
              onChange={(e) => setGender(e.target.value)}
              errormessage={genderError}
            />
          </div>
          <div className="flex gap-2 items-center mt-6 mb-8 tab:mb-11 cursor-pointer">
            <input type="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange} className="w-6 h-6"/>
            <p className="m-0" onClick={handleRedirect}>By signing up, you agree to <span className="text-primary">Heala’s Terms & Conditions</span></p>
          </div>
        </div>
        <Button fullWidth onClick={handleChecked}>
          {(signupLoading || createProfileLoading) ? (
            <CircularProgress
              style={{ width: 20, height: 20, color: "#fff" }}
            />
          ) : (
            "Next"
          )}
        </Button>
        <Button fullWidth onClick={handleChecked}>
          {(signupLoading || createProfileLoading) ? (
            <CircularProgress
              style={{ width: 20, height: 20, color: "#fff" }}
            />
          ) : (
            "Create Account"
          )}
        </Button>
      </form>
    </div>
  );
};
