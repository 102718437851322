import React from 'react';
import dayjs from 'dayjs';
import { Button, Avatar } from '@mui/material';
import { ReactComponent as Star } from '../assets/svgs/🦆 emoji _white medium star_.svg';
import { classNames, convertFirstLetterToUpperCase, statusStyles, stringAvatar, numberWithCommas } from './helperFunctions';
import useAlert from '../hooks/useAlert';
import useTimeDifferenceFromNow from '../hooks/useDynamicTimeDifferenceFromNow';

const ConsultationCard = ({ consultation, buttonClassName }) => {
  const { displayAlert } = useAlert();

  const consultationDateTime = dayjs(consultation?.time);
  const formattedConsultationTime = consultationDateTime.format('HH:mm');
  const formattedConsultationDate = consultationDateTime.format('DD MMMM YYYY')

  const timeDifference = useTimeDifferenceFromNow(consultationDateTime, 5) + 1; // Round up to the nearest minute 

  const isExpired = timeDifference < -30; // can't join after 30 minutes
  const isUpcoming = timeDifference > 0;
  const isWithinJoiningTime = timeDifference > -30 && timeDifference <= 15;

  const canJoin = !isExpired && isWithinJoiningTime;

  function handleJoinConsultation() {
    if (canJoin) {
      window.open(`${process.env.REACT_APP_CONSULTATION_BASE_URL}${consultation?._id}`, "_blank");
    } else {
      if (isExpired) {
        displayAlert('error', 'This consultation is no longer available!')
      } else if (isUpcoming && !isWithinJoiningTime) {
        displayAlert('error', 'Join Consultation will be available 15 minutes before the scheduled time')
      }
    }
  }

  return (
    <div className='py-6 px-3 flex flex-col border border-gray-50 rounded-lg w-full'>
      <h5 className='font-medium'>Scheduled Consultation</h5>
      <div className='mt-6'>
        <div className='flex gap-3'>
          <div className='flex self-center table-image'>
            {consultation?.doctor?.picture ? (
              <img src={consultation?.doctor?.picture} alt='doc' className="w-13 h-13 rounded-full" />
            ) : (
              <Avatar {...stringAvatar(consultation?.doctor?.lastName, consultation?.doctor?.firstName)} sx={{ fontSize: "15px", fontFamily: "Euclid Circular A" }} />
            )}
          </div>
          <div>
            <h3 className="font-medium text-[19px]">{consultation?.doctor?.lastName} {consultation?.doctor?.firstName}</h3>
            <p className="font-normal text-[16px]">{consultation?.doctor?.specialization}</p>
            {consultation?.doctor?.rating && (
              <div className='flex items-center gap-1'>
                {[...Array(Math.floor(consultation?.doctor?.rating))].map((_, index) => (
                  <Star key={index} />
                ))}
                <p className="text-[11px]">{Number.isInteger(consultation?.doctor?.rating) ? `${consultation?.doctor?.rating}.0` : consultation?.doctor?.rating}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mt-[15px]'>
        <div className='flex gap-[10px] flex-wrap cursor-pointer'>
          <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{formattedConsultationDate}</div>
          <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{formattedConsultationTime}</div>
          <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">30mins</div>
          <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{convertFirstLetterToUpperCase(consultation?.contactMedium) || "None"}</div>
        </div>
      </div>
      <div className='mt-6 flex flex-col gap-2'>
        <h5 className='font-medium'>Consultation Fee: <span className='text-primary'>{consultation?.fee ? `N${numberWithCommas(consultation?.fee)}` : "Nil"}</span></h5>
        <div className='flex gap-2 items-center'>
          <h5 className='font-medium'>Status:</h5>
          <p className={classNames(
            statusStyles[convertFirstLetterToUpperCase(consultation?.status)],
            'px-2 rounded-[10px] border'
          )}>
            {convertFirstLetterToUpperCase(consultation?.status)}
          </p>
        </div>
      </div>
      <div className='mt-6 flex-1 flex items-end'>
        <Button
          variant='contained'
          className={buttonClassName}
          onClick={handleJoinConsultation}
          style={!canJoin ? { cursor: 'not-allowed' } : {}}
          title='Join Consultation'
        >
          Join Consultation
        </Button>
      </div>
    </div>
  );
};

export default ConsultationCard;
