import React, { useState } from "react";
import { Input } from "../Input";
import { Button } from "../Button";
import useAlert from "../../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import eyeclosed from "../../assets/icons/eye-closed.svg";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../api/graphQL/query";
import { CircularProgress } from "@mui/material";

export const SignInForm = () => {
  const navigate = useNavigate();
  const { displayAlert } = useAlert();
  const [isPassword, setIsPassword] = useState(true);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordInputType = isPassword ? "password" : "text";
  
  const [loginMutation, { loading, error }] = useMutation(LOGIN);

  const handleRedirect = () => {
    window.open("https://heala.ng/terms/", "_blank");
  }  

  const handleLogin = () => {
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Please enter your email address.");
    }
    if (!password) {
      setPasswordError("Please enter a password.");
    }

    if (!email || !password ) {
      return;
    }

    loginMutation({
      variables: {
        email: email,
        password: password,
        authType: "normal"
      }
    })
      .then((response) => {
        setEmail("");
        setPassword("");
        
        displayAlert("success", "Login successful.");

        const id = response.data.login.account.dociId;
        const token = response.data.login.account.access_token;
        localStorage.setItem("authId", id);
        localStorage.setItem("authToken", token);
        
        navigate("/dashboard");
      })
      .catch((error) => {
        const errorMessage = sessionStorage.getItem("error");
        displayAlert('error', errorMessage)
        setTimeout(() => {
          sessionStorage.removeItem("error");
        }, 3000)
      });
  };

  return (
    <div className="w-full">
      <h1 className="font-semibold text-black text-[40px] leading-[50.72px] m-0">
        Sign in
      </h1>
      <p className="text-base leading-[20.29px] m-0 mt-2">
        Welcome back. Let's get you signed in.
      </p>
      <form className="heala-otp-form mt-12">
        <div className="space-y-6 mb-[62px]">
          <Input
            type="email"
            label="Email Address"
            placeholder="Email Address"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            errormessage={emailError}
          />
          <div>
            <Input
              type={passwordInputType}
              label="Password"
              placeholder="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              errormessage={passwordError}
              endAdornment={
                <button
                  type="button"
                  className="cursor-pointer"
                  onClick={() => setIsPassword(!isPassword)}
                >
                  <img src={eyeclosed} alt="eye closed" />
                </button>
              }
            />
            <p className="text-primary leading-5 m-0 mt-[14px] cursor-pointer" onClick={() => navigate("/forgotpassword")}>
              Forgot password?
            </p>
          </div>
        </div>
        <Button
          fullWidth
          onClick={handleLogin}
        >
          {loading ? (
            <CircularProgress
              style={{ width: 20, height: 20, color: "#fff" }}
            />
          ) : (
            "Sign in"
          )}
        </Button>
        <p className="text-center leading-[20.29px] m-0 mt-[14px]">
          Don't have an account?{" "}
          <span
            className="text-primary underline cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            Sign up
          </span>
        </p>
      </form>
    </div>
  );
};
