import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { ProfileContext } from '../../context/profileContext';
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../helperFunctions';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Exit } from '../../assets/icons/exits.svg';
import { ReactComponent as Steth } from '../../assets/icons/steth.svg';
import { ReactComponent as Syringe } from '../../assets/icons/syringe.svg';
import { ReactComponent as FirstAid } from '../../assets/icons/firstaid.svg';
import { ReactComponent as Homes } from '../../assets/icons/homes.svg';
import { ReactComponent as Steths } from '../../assets/icons/steths.svg';
import { ReactComponent as FirstAids } from '../../assets/icons/firstaids.svg';
import { ReactComponent as Syringes } from '../../assets/icons/syringes.svg';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import Profile from '../profile/profile';
import Drawers from '../drawers';
import Menubox from '../menubox/menubox';
import { Skeleton } from '@mui/material';

export default function Menu({openProfile, setOpenProfile, setMenu, menu, activeItem, setActiveItem, loading}) {
    const widgetLogo = localStorage.getItem('logo');
    const { profile } = useContext(ProfileContext);
    const data = profile?.profiles?.data[0];
    const navigate = useNavigate();
    
    const handleItemClick = (itemName, active) => {
        navigate(itemName);
        setActiveItem(active);
    };
    
    const handleDrawerOpen = () => {
        setMenu(false);
        setOpenProfile(true);
    }
    
    return (
        <>
            <div className='h-full flex flex-col '>
                <div className='text-center'>
                    <div className='flex justify-center mb-3'>
                        {loading ? (
                            <Skeleton animation="wave" variant="circular" width={50} height={50} />
                        ) : (
                            data?.image === null || data?.image === "" ? (
                                <Avatar {...stringAvatar(data?.firstName, data?.lastName)} sx={{height:"50px", width:"50px"}}/>
                            ) : (
                                <img src={data?.image} alt='profile' className='h-[50px] w-[50px] rounded-full' />
                            )
                        )}
                    </div>
                    {loading ? (
                        <>
                            <Skeleton animation="wave" height={10} />
                            <Skeleton animation="wave" height={10} />
                            <Skeleton animation="wave" style={{marginTop: '24px'}} height={10} />
                        </>
                    ) : (
                        <>
                            <h5 className='font-medium text-base'>{data?.firstName} {data?.lastName}</h5>
                            <p className='cursor-pointer text-[13px]'>{data?.accountData?.email}</p>
                            <p className='mt-4 font-medium text-[13px] text-primary cursor-pointer' onClick={() => setOpenProfile(!openProfile)}>Edit Profile</p>
                        </>
                    )}
                </div>
                <div className={`mt-10 flex flex-col ${loading ? 'gap-1' : 'gap-3'}`} >
                    {loading ? (
                        <Skeleton animation="wave" style={{borderRadius: '8px'}} height={90}/>
                    ) : (
                        <Menubox 
                            setMenu={setMenu}
                            menu={menu}
                            onClicked={() => handleItemClick('/dashboard', 'dashboard')}
                            activeItem={activeItem === 'dashboard'}
                        >
                            <Home />
                            <Homes className='leftbox-svg' />
                            <p className='text-[16px]'>Dashboard</p>
                        </Menubox>
                    )}
                    {loading ? (
                        <Skeleton animation="wave" style={{borderRadius: '8px'}} height={90}/>
                    ) : (
                        <Menubox 
                            setMenu={setMenu}
                            menu={menu}
                            onClicked={() => handleItemClick('/consultations', 'consultations')}
                            activeItem={activeItem === 'consultations'}
                        >
                            <Steths  />
                            <Steth className='leftbox-svg'/>
                            <p className='text-[16px]'>Consultations</p>
                        </Menubox>
                    )}
                    {loading ? (
                        <Skeleton animation="wave" style={{borderRadius: '8px'}} height={90}/>
                    ) : (
                        <Menubox 
                            setMenu={setMenu}
                            menu={menu}
                            onClicked={() => handleItemClick('/prescription', 'prescription')}
                            activeItem={activeItem === 'prescription'}
                        >
                            <FirstAid />
                            <FirstAids className='leftbox-svg' />
                            <p className='text-[16px]'>Prescriptions</p>
                        </Menubox>
                    )}
                    {loading ? (
                        <Skeleton animation="wave" style={{borderRadius: '8px'}} height={90}/>
                    ) : (
                        <Menubox 
                            setMenu={setMenu}
                            menu={menu}
                            onClicked={() => handleItemClick('/tests', 'test')}
                            activeItem={activeItem === 'test'}
                        >
                            <Syringe />
                            <Syringes className='leftbox-svg'/>
                            <p className='text-[16px]'>Test</p>
                        </Menubox>
                    )}
                    {loading ? (
                        <Skeleton animation="wave" style={{borderRadius: '8px'}} height={90}/>
                    ) : (
                        <Menubox 
                            setMenu={setMenu}
                            menu={menu}
                            onClicked={() => handleItemClick('/signout', 'exit')}
                            activeItem={activeItem === 'exit'}
                        >
                            <Exit />
                            <Exit className='leftbox-svg'/>
                            <p className='text-[16px]'>Sign out</p>
                        </Menubox>
                    )}
                </div>
                <div className='flex flex-col items-center justify-end gap-2 flex-1 py-[47.68px]'>
                    <p>poweredby</p>
                    { <Logo /> }
                </div>
            </div>

            <Drawers openDrawer={openProfile} setOpenDrawer={setOpenProfile} handleDrawerOpen={handleDrawerOpen} setOpenProfile={setOpenProfile}>
                <Profile setOpenProfile={setOpenProfile} />
            </Drawers>
        </>
    )
}