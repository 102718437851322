import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../Input';
import { Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { RESET_PASSWORD } from '../../api/graphQL/query';
import { useMutation } from '@apollo/client';
import { ReactComponent as Previous } from "../../assets/svgs/arrow.svg";

export const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const navigate = useNavigate();
    const errorMessage = sessionStorage.getItem("error");
    const [resetMutation, { loading, error }] = useMutation(RESET_PASSWORD);

    const handleForgot = async () => {
        setEmailError("");
        
        if (!email) {
            setEmailError("Please enter your email address.");
            return;
        }

        try {
            setEmail("");
            
            const resetResponse = await resetMutation({
                variables: {
                  email: email
                }
            })
            const state = {
                email: resetResponse.data.resetPassword.account.email,
                user: 'forgot'
            };
            navigate("/otp", { state });
        } catch (error) {
            if (errorMessage === "Email not found") {
                setEmailError(errorMessage);
            }
            console.log(error);
        }
    }

    return (
        <div>
            <div className="pt-5">
                <Previous onClick={() => navigate('/start')} />
            </div>
            <div className="w-full h-full flex flex-col justify-center">
                <h1 className="font-semibold text-black text-[40px] leading-[50.72px] m-0">
                    Forgot Password?
                </h1>
                <p className="text-base leading-[20.29px] m-0 mt-2">
                    Kindly provide us with the email address linked to your account.
                </p>
                <form className="mt-12">
                    <div className="space-y-6 mb-[30px]">
                        <Input
                            type="email"
                            label="Email Address"
                            placeholder="Email Address"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            errormessage={emailError}
                        />
                    </div>
                    <Button 
                        fullWidth 
                        sx={{
                            textTransform: "capitalize",
                            fontSize: "13px",
                            fontFamily: "Euclid Circular A" ,
                            borderRadius: "8px",
                            backgroundColor: "#3E5EA9",
                            color: "#FFF",
                            padding: "12px 24px",
                            "&": {
                                backgroundColor: "#3E5EA9 !important",
                            },
                        }}
                        onClick={handleForgot} >
                        {loading ? (
                            <CircularProgress
                            style={{ width: 20, height: 20, color: "#fff" }}
                            />
                        ) : (
                            "Reset"
                        )}
                    </Button>
                </form>
            </div>
        </div>
    )
}
