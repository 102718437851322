import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

export default function Exit() {
  const navigate = useNavigate();
  const doctor = localStorage.getItem('isDoctor');

  useEffect(() => {
    handleClearSessionStorage();
    localStorage.removeItem('profile');
    localStorage.removeItem('consultationInfo');
    localStorage.removeItem('psRef');
    
    if (doctor == 'true') {
      setTimeout(() => {
        navigate('/access');
        localStorage.removeItem("form1Data", JSON.stringify({}))
        localStorage.removeItem("formData", JSON.stringify({}))
      }, 2000);  
    } else {
      setTimeout(() => {
        navigate('/access');
      }, 2000);    
    }
  }, [])

  const handleClearSessionStorage = () => {
    const token = sessionStorage.getItem('token');
    
    for (let i = sessionStorage.length - 1; i >= 0; i--) {
      const key = sessionStorage.key(i);
      if (key !== 'token') {
        sessionStorage.removeItem(key);
      }
    }
  
    sessionStorage.setItem('token', token);
  };
  
  return (  
    <div>
      <Loader type="fullPage" />
    </div>
  )
}
