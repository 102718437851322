import React from "react";
import { makeStyles } from "@mui/styles";
import { Button as MuiButton } from "@mui/material";

const useButtonStyles = makeStyles((theme) => ({
    button: {
      height: "48px",
      border: "none",
      borderRadius: "8px",
      backgroundColor: "#3E5EA9",

      "&.MuiButtonBase-root": {
        backgroundColor: "#3E5EA9",
        color: "#fff"
      },

      "&.MuiButtonBase-root:hover": {
        backgroundColor: "#355090",
      },
    },
  }));

export const Button = ({children, ...props}) => {
    const classes = useButtonStyles();
    return (
        <MuiButton className={classes.button} {...props}>{children}</MuiButton>
    )
}