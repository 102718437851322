import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';

export default function Drawers(
    {
        openDrawer, 
        setOpenDrawer, 
        width, 
        children, 
        handleDrawer, 
        side, 
        mdwidth,
        minwidth,
        handleDrawerOpen
    }
) {

    
    return (
        <SwipeableDrawer
            anchor= {side || "right"}
            open={openDrawer}
            onClose={() => setOpenDrawer ? setOpenDrawer(false) : handleDrawer()}
            onOpen={() => setOpenDrawer ? setOpenDrawer(true) : handleDrawerOpen()}
            sx={{
                width: {
                    xs: minwidth || '100%',
                }
            }}
        >
            <Box
                sx={{ 
                    width: {
                        xs: '100%',
                        sm: mdwidth || width || 582,
                        md: mdwidth,
                        lg: width
                    }, 
                    height:'100%' 
                }}
                role="presentation"
            >
                {children}
            </Box>
        </SwipeableDrawer>
    )
}