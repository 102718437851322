import React from 'react'
import Menu from '../menu/menu';

export default function LeftBar({activeItem, setActiveItem, openProfile, setOpenProfile, loading}) {
    return (
        <div className='w-[260px] hidden desktop:flex flex-col h-screen px-8 pt-10'>
            <div className='flex-grow fixed leftbar'>
                <Menu 
                    setOpenProfile={setOpenProfile} 
                    openProfile={openProfile} 
                    activeItem={activeItem} 
                    setActiveItem={setActiveItem}
                    loading={loading}
                />
            </div>
        </div>
    )
}