import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Input } from "../Input";
import { Button, CircularProgress } from '@mui/material';
import { COMPLETE_RESET_PASSWORD } from '../../api/graphQL/query';
import { useMutation } from '@apollo/client';

export const ResetPasswordForm = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();

    const [resetMutation, { loading, error }] = useMutation(COMPLETE_RESET_PASSWORD);

    const handleReset = async () => {
        setPasswordError("");

        if (!password && !confirmPassword) {
            setPasswordError("Please enter your new password.");
            return;
        }

        if (password !== confirmPassword) {
            setPasswordError("Passwords are not the same.");
            return;
        }

        try {
            setPassword("");
            setConfirmPassword("");
            
            const resetPasswordResponse = await resetMutation({
                variables: {
                  email: '',
                  newPassword: password,
                  token: 1111
                }
            })

            navigate('/resetsuccessful');
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <div className="w-full">
            <h1 className="font-semibold text-black text-[40px] leading-[50.72px] m-0">
                Set New Password
            </h1>
            <p className="text-base leading-[20.29px] m-0 mt-2">
                Kindly set a new password for your account
            </p>
            <form className="mt-12">
                <div className="space-y-6 mb-[30px]">
                    <div>
                        <Input
                            type="password"
                            label="Password"
                            placeholder="Password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            errormessage={passwordError}
                        />
                    </div>
                    <div>
                        <Input
                            type="password"
                            label="Confirm Password"
                            placeholder="Confirm Password"
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            errormessage={passwordError}
                        />
                    </div>
                </div>
                <Button 
                    fullWidth 
                    sx={{
                        textTransform: "capitalize",
                        fontSize: "13px",
                        fontFamily: "Euclid Circular A" ,
                        borderRadius: "8px",
                        backgroundColor: "#3E5EA9",
                        color: "#FFF",
                        padding: "12px 24px",
                        "&": {
                            backgroundColor: "#3E5EA9 !important",
                        },
                    }}
                    onClick={handleReset} >
                    {loading ? (
                        <CircularProgress
                        style={{ width: 20, height: 20, color: "#fff" }}
                        />
                    ) : (
                        "Save"
                    )}
                </Button>
            </form>
        </div>
    )
}
