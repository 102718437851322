import React, { useEffect, useContext, useState } from "react";
import { format } from "date-fns";
import { AppContext } from "../../App";
import Copy from "../../components/Copy";
import useAlert from "../../hooks/useAlert";
import Loader from "../../components/Loader";
import { useParams } from "react-router-dom";
import { useFetchAPI } from "../../api/axios/axios";

const PaymentReceipt = () => {
  const { successRef } = useParams();
  const { displayAlert } = useAlert();
  const { setLoading, loading } = useContext(AppContext);
  const { CHECK_PAYMENT, GET_CONSULTATION_INFO } = useFetchAPI();
  const [consultationDetails, setConsultationDetails] = useState(null);
  const consultationInfo = JSON.parse(
    sessionStorage.getItem("consultationInfo")
  );

  useEffect(() => {
    setLoading(true);
    CHECK_PAYMENT(successRef)
      .then((res) => {
        const status = res?.data?.chargeResponse?.status;
        if (status === "success") {
          //setLoading(false);
          return GET_CONSULTATION_INFO(consultationInfo._id);
        } else {
          displayAlert("error", `Payment status is: ${status}`);
          throw Error(`Payment status is: ${status}`);
        }
      })
      .then((consultationRes) => {
        const data = consultationRes?.data?.data || "error";
        setConsultationDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        setConsultationDetails("error");
        setLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" h-screen flex flex-col items-center text-[#2c2c2c] font-euclid m-5">
      {loading && <Loader type="fullPage" />}
      {!consultationDetails && <p></p>}
      {consultationDetails === "error" && (
        <p>Something went wrong, try again.</p>
      )}
      {consultationDetails && consultationDetails !== "error" && (
        <>
          <h1 className="text-3xl font-semibold text-center my-5">
            Payment receipt
          </h1>
          <div className="max-w-[95%] lg:max-w-screen-sm p-5 rounded-md border border-[#c6c6c6] space-y-5 m-2">
            <p>
              Your appointment with{" "}
              <span className="font-semibold">
                Dr. {consultationDetails?.doctorData?.firstName}
              </span>{" "}
              was successful with the following details
            </p>
            <p>
              <span className="font-semibold">Date:</span>{" "}
              {`${format(new Date(consultationDetails?.time), "P")}`}
            </p>
            <p>
              <span className="font-semibold">Time:</span>{" "}
              {`${format(new Date(consultationDetails?.time), "p")}`}
            </p>
            <p>
              <span className="font-semibold">Duration:</span> 30 minutes
            </p>
            <div className="md:flex items-center">
              <p style={{ overflowWrap: "anywhere" }}>
                <span className="font-semibold">Meeting link:</span>{" "}
                {consultationInfo?.url}
              </p>
              <Copy text={consultationInfo?.url} name="meeting link" />
            </div>
            <p className="text-sm font-semibold">
              Please make sure to be available at the scheduled time. The
              consultation will take place online, and you can access it through
              the meeting link.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentReceipt;
