import React, {useContext, useState, useRef} from 'react'
import Avatar from '@mui/material/Avatar';
import { stringAvatar } from '../helperFunctions';
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import Evercare from '../../assets/images/evercare.png';
import Reliance from '../../assets/images/reliance.png';
import Selectprofile from './selectprofile';
import { ProfileContext } from '../../context/profileContext';
import { AppContext } from "../../App";
import useAlert from '../../hooks/useAlert';
import Loader from '../Loader';
// import { ReactCrop } from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css'

export default function Profile({setOpenProfile}) {
    const { token } = useContext(AppContext);
    const { displayAlert } = useAlert();
    const { profile } = useContext(ProfileContext);
    const data = profile?.profiles?.data[0];
    const [profilePicture, setProfilePicture] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const [crop, setCrop] = useState();
    const fileInputRef = useRef(null);
    const logo = localStorage.getItem('logo');
    
    const myBaseImg = async (file) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Api-Key ${token}`);
      
        const formData = new FormData();
        formData.append("file", file, file.name);
      
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formData,
          redirect: 'follow'
        };
      
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rest/media/upload/api`, requestOptions);
        const result = await response.json();
        return result;
    };

    const uploadFile = async (event) => {
        const file = event.target.files[0];
        try {
            setIsLoading(true);
            const result = await myBaseImg(file);
            setProfilePicture(result.data.mediaUrl);
            console.log(result);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleUpdateProfilePicture = () => {
        fileInputRef.current.click();
    };

    return (
        <div className='flex flex-col font-euclid h-full my-10'>
            <div className="cursor-pointer flex w-full justify-between items-center px-6">
                <h3 className='font-medium text-[25px]'>Profile</h3>
                <Exit onClick={() => setOpenProfile(false)} />
            </div>
            <div className='mt-6 px-6'>
                <div className='items-center flex flex-col w-full'>
                    {isLoading ? (
                        <Avatar sx={{width: "120px", height:"120px", fontSize:"40px"}}>
                            <Loader type='inline' />
                        </Avatar>
                    ): (
                        <>
                            {profilePicture ? (
                                <img src={profilePicture} alt='profile' className='w-[128px] h-[128px] rounded-full' />
                            ) : (
                                <>
                                    {data?.image === null || data?.image === '' ? (
                                        <Avatar {...stringAvatar(data?.firstName, data?.lastName)} sx={{width: "120px", height:"120px", fontSize:"40px", fontFamily: "Euclid Circular A"}}/>
                                    ) : (
                                        <img src={data?.image} alt='profile' className='w-[128px] h-[128px] rounded-full' />
                                    )}
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={uploadFile}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <p 
                        onClick={handleUpdateProfilePicture}
                        className='mt-4 text-primary cursor-pointer font-medium text-[13px] hover:opacity-75 transition ease-out'
                    >
                        Update Profile Picture
                    </p>
                    {/* <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                        <img src={Evercare} alt='img'/>
                    </ReactCrop> */}
                </div>
                <Selectprofile picture={profilePicture} setOpenProfile={setOpenProfile}/>
            </div>
           {logo === '' ?  <div className='w-full bg-secondary'>
                <div className='px-6 md:px-12 py-9'>
                    <h3 className='font-medium text-[25px]'>Hospital & HMO Information</h3>
                    <div className='mt-10 flex flex-col tab:flex-row gap-4 w-full justify-between'>
                        <div className='bg-white rounded-lg w-full h-[216px] flex justify-center items-center'>
                            <img src={logo} alt='logo' className='w-[110.77px]'  />
                        </div>
                        {/* <div className='bg-white rounded-lg w-full tab:w-[235px] h-[216px]'>
                            <div className='flex flex-col justify-center items-center h-full'>
                                <div className='h-[75%] flex items-center'>
                                    <img src={Reliance} alt='logo' className='w-[150.22px]' />
                                </div>
                                <div className='-mt-7 text-center'>
                                    <p>HMO ID</p>
                                    <p>302893FFG3</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>: ''}
        </div>
    )
}