import { makeStyles } from "@mui/styles";

export const useDefaultPageStyles = makeStyles((theme) => ({
     root: {
          maxHeight: "100vh",
          width: "70%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",

          gap: theme.spacing(4),
          [theme?.breakpoints?.down(800)]: {
               width: "100%",
               gap: theme.spacing(10),
               padding: theme.spacing(0, 0),
          },
          [theme?.breakpoints?.down(600)]: {
               width: "100%",
               gap: theme?.spacing(5),
               padding: theme?.spacing(0, 0),
          },
     },
     logo: {
          width: 200,
          margin: "2rem 0rem 0rem 0rem",
          height:'auto',
          [theme?.breakpoints?.down(600)]: {
               width: 200,
               height:'auto',

               margin: '3% 5% 1%',
          },
          [theme?.breakpoints?.down(800)]: {
               margin: '10% 5% 1%',
          },
     },
     banner: {
          width: "100%",
          margin: 0,
          [theme?.breakpoints?.down(600)]: {
               height: "auto",
          },
     },
     bannerContainer: {
          width: "80%",
          margin: 0,
          [theme?.breakpoints?.down(600)]: {
               width: "100%",
          },
     },
     title: {
          textAlign: "center",
          letterSpacing: "-0.01em",
          fontFamily: "Euclid Circular A",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: 35.3403,
          margin: 0,
          color: "#262626",

          [theme?.breakpoints?.down(600)]: {
               fontWeight: 700,
               fontSize: 28,
          },
     },
     description: {
          fontFamily: "Euclid Circular A",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 14,
          color: "#A6A6A6",
          textAlign: "center",
          marginTop: 16,
          [theme?.breakpoints?.down(600)]: {
               fontWeight: 400,
               fontSize: 14,
               width: "100%",
               alignItems: "center",
               justifyContent: "center",
               flexDirection: "column",
          },
     },
     button: {
          background: "transparent",
          color:'transparent',
          borderRadius: ` 12px !important`,
          padding: `${theme.spacing(1.5, 12)} !important`,
          fontFamily: "Euclid Circular A !important",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: 21,
     },
     center: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
     },
     br: {
          [theme?.breakpoints?.down(600)]: {
               display: "block",
          },
     },
     powered: {
          // position: "fixed",
          // bottom: theme.spacing(2),
          // right: theme.spacing(2),
          "& img": {

               marginBottom: "0.7rem",
               width: 130,
               marginTop: theme.spacing(2),
          },

          [theme?.breakpoints?.down(600)]: {
               // position: "absolute",
               width: "100%",
               alignItems: "center",
               justifyContent: "center",
               display: "flex",
               right: theme?.spacing(0),

               "& img": {
                    width: 150,
                    marginTop: theme?.spacing(3),
               },
          },
     },
}));