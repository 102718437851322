import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { ProfileProvider } from "./context/profileContext";
import { ConsultationProvider } from "./context/consultationContext";
import { PrescriptionProvider } from "./context/prescriptionContext";
import { TestProvider } from "./context/testContext";
import { onError } from "@apollo/client/link/error";



const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      sessionStorage.setItem("error", message);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    sessionStorage.setItem("network", networkError);
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_BASE_URL,
  cache: new InMemoryCache(),
  link: errorLink.concat(httpLink),
});

// add action to all snackbars
const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      action={(key) => (
        <button
          onClick={onClickDismiss(key)}
          className="text-white font-semibold text-sm"
          style={{
            cursor: "pointer",
          }}
        >
          Dismiss
        </button>
      )}
    >
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ProfileProvider>
            <TestProvider>
              <PrescriptionProvider>
                <ConsultationProvider>
                  <App />
                </ConsultationProvider>
              </PrescriptionProvider>  
            </TestProvider>
          </ProfileProvider>
        </ApolloProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>
);

