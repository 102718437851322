import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Success } from '../../assets/svgs/success.svg'; 
import useAlert from '../../hooks/useAlert';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loader from '../Loader';
import { useQuery } from '@apollo/client';
import { CHECK_PAYMENT, GET_CONSULT } from '../../api/graphQL/query';

const useStyles = makeStyles(() => ({
    Button: {
        padding: "18px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%'
    },
}));

export default function Stepthree({setSuccessDrawer, setConfirmDrawer, setDrawer, handleReset}) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { displayAlert } = useAlert();
    const { setLoading, loading } = useContext(AppContext);
    
    const [consultationDetails, setConsultationDetails] = useState(null);
    const consultationInfo = JSON.parse(
        localStorage.getItem("consultationInfo")
    );
    const successRef = localStorage.getItem("psRef");
    const authToken = localStorage.getItem("authToken");

    const { loading: paymentLoading, error: paymentError, data: paymentData } = useQuery(CHECK_PAYMENT, {
        variables: { reference: successRef },
    });

    const { loading: consultLoading , error: consultError, data: consultData } = useQuery(GET_CONSULT, {
        skip: !authToken || !consultationInfo?._id,
        variables: { id: consultationInfo._id},
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });

    useEffect(() => {
        setLoading(true);
        if (paymentData) {
            const status = paymentData?.checkCharge?.chargeResponse?.status;
            if (status === "success") {
                setConsultationDetails(consultData?.getConsultation);
                setLoading(false);
            } else {
                displayAlert("error", `Payment status is: ${status}`);
                setLoading(false);
            }
        } else {
            setConsultationDetails('error');
        }
    }, [paymentData, consultData]);

    const handleRedirect = () => {
        navigate('/dashboard', { state: { reload: true } });
    }
    
    const handleRedirectConsult = () => {
        navigate('/consultations', { state: { reload: true } });
    }
    
    return (
    <>
        {loading && <Loader type="fullPage" />}
        {!consultationDetails && <p></p>}
        {consultationDetails === "error" && (
            <p>Something went wrong, try again.</p>
        )}
        {consultationDetails && consultationDetails !== "error" && (
            <div className='flex flex-col font-euclid my-12 mx-6 h-full'>
                <div 
                    className="cursor-pointer flex w-full justify-end" 
                    onClick={() => {
                        handleReset();
                        setSuccessDrawer(false);
                        setConfirmDrawer(false);
                        setDrawer(false);
                    }}
                >
                    <Exit  />
                </div>
                <div className='w-[90%] justify-center flex flex-col place-items-center flex-1 absolute top-[28%]'>
                    <Success />
                    <div className='mt-6 flex flex-col items-center w-full min-[520px]:w-[60%]'>
                        <h3 className='font-medium text-[25px] text-center'>Appointment Successful</h3>
                        <p className='text-center'>Your appointment with {consultationDetails?.doctorData?.lastName} {consultationDetails?.doctorData?.firstName} has been booked successfully.</p>
                        <div className='mt-6 w-[60%]'>
                            <Button variant='contained' className={classes.Button} onClick={handleRedirectConsult}>View Appointment</Button>
                            <p 
                                className='mt-4 text-primary cursor-pointer text-center' 
                                onClick={handleRedirect}
                            >
                                Return Home
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
  )
}
