import React from 'react'
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import SelectLabels from './select';
import { stringAvatar } from '../helperFunctions';
import { Avatar } from '@mui/material';

export default function Stepone(
  {
    handleDrawerClose,
    setConfirmDrawer, 
    confirmDrawer,
    doc,
    time,
    setTime,
    channel,
    setChannel,
    symptoms, 
    setSymptoms,
    level,
    setLevel, 
    expect, 
    setExpect,
    aob,
    setAob,
    setFee,
    batches,
    setBatches,
    handleReset,
    selectedDate,
    age,
    setAge,
    weight,
    setWeight,
    height,
    setHeight,
  }) {


   
  return (
    <>
      <div className='flex flex-col font-euclid mt-12 mx-6 h-full'>
        <div className="cursor-pointer flex w-full justify-end" onClick={() => {
          handleReset();
          handleDrawerClose();
        }}>
          <Exit  />
        </div>
        <div className='mt-7 flex gap-3'>
          <div className='flex self-center'>
          {doc?.picture ? (
            <img src={doc.picture} alt='doctor' className="rounded-full h-[65px] w-[65px] object-cover"/>
          ) : <Avatar {...stringAvatar(doc.lastName, doc.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/> }
          </div>
          <div>
            <h3 className="font-medium text-[25px]">{doc.lastName} {doc.firstName}</h3>
            <p className="font-normal text-[16px]">{doc?.specialization  == 'Nil' ? doc?.cadre : doc?.specialization}</p>
            {doc.rating ? 
              <div className='flex items-center gap-1'>
                {[...Array(Math.floor(doc?.rating))].map((_, index) => (
                  <Star key={index} />
                ))}
                <p className="font-normal text-[11px]">
                  {Number.isInteger(doc.rating) ? `${doc.rating}.0` : doc.rating}
                </p>
              </div>
            : ''}
          </div>
        </div>
        <div className='mt-10 flex-1'>
          <SelectLabels 
            handleDrawerClose={handleDrawerClose} 
            setConfirmDrawer={setConfirmDrawer} 
            confirmDrawer={confirmDrawer}
            doc={doc}
            time={time}
            setFee={setFee}
            selectedDate={selectedDate}
            setTime={setTime}
            channel={channel}
            setChannel={setChannel}
            symptoms={symptoms} 
            setSymptoms={setSymptoms}
            level={level} 
            setLevel={setLevel}
            expect={expect} 
            setExpect={setExpect}
            aob={aob}
            setAob={setAob}
            batches={batches}
            setBatches={setBatches}
            handleReset={handleReset}
            age={age}
            setAge={setAge}
            weight={weight}
            setWeight={setWeight}
            height={height}
            setHeight={setHeight}
          />
        </div>
      </div>
    </>
  )
}