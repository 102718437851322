import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import Skeleton from "@mui/material/Skeleton";
import { useDefaultPageStyles } from "./styles";
import { Button, Typography } from "@mui/material";
/* import powered from "../../assets/images/powered.png"; */
import banner from "../../assets/images/bhp-banner.png";

export const Defualt = ({
  openForm,
  widgetColor,
  widgetLogo,
  id,
  plan,
  category,
}) => {
  const classes = useDefaultPageStyles();
  const { loading } = useContext(AppContext);
  const navigate = useNavigate();

  const updateManifest = (manifest) => {
    const url = process.env.PUBLIC_URL + "/manifest.json";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(manifest),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("Manifest updated successfully!");
      })
      .catch((error) => {
        console.error("There was a problem updating the manifest:", error);
      });
  };

  useEffect(() => {
    document.title = id ? `${id}` : "Heala App";
    const favicon = document.querySelector("link[rel='icon']");
    favicon.href = id
      ? `${widgetLogo}`
      : widgetLogo
      ? `${widgetLogo}`
      : "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg";

    fetch(process.env.PUBLIC_URL + "/manifest.json")
      .then((response) => response.json())
      .then((manifest) => {
        manifest.name = id ? `${id}` : "Heala App";
        manifest.short_name = id ? `${id}` : "Heala App";
        manifest.icons[1].src = widgetLogo
          ? widgetLogo
          : "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg";
        manifest.icons[2].src = widgetLogo
          ? widgetLogo
          : "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg";
        // updateManifest(manifest);
      })
      .catch((error) => {
        console.error("There was a problem fetching the manifest:", error);
      });
  }, [id, widgetLogo]);

  const displayLogo = () => {
    return id ? (
      <img
        src={widgetLogo}
        alt=""
        srcSet=""
        className={classes.logo}
        style={{ maxHeight: "64px", objectFit: "contain" }}
      />
    ) : widgetLogo ? (
      <img
        src={widgetLogo}
        alt=""
        srcSet=""
        className={classes.logo}
        style={{ maxHeight: "64px", objectFit: "contain" }}
      />
    ) : (
      <img
        src={
          "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg"
        }
        alt=""
        srcSet=""
        className={classes.logo}
        style={{ maxHeight: "64px", objectFit: "contain" }}
      />
    );
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Skeleton
          style={{ marginTop: "5%" }}
          animation="wave"
          variant="rounded"
          width={200}
          height={50}
        />
      ) : (
        displayLogo()
      )}
      <div className={classes.bannerContainer}>
        <img src={banner} alt="" srcSet="" className={classes.banner} />
      </div>
      <div className={classes.center}>
        <h3 className={classes.title}>Talk to a Doctor</h3>
        <p className={classes.description}>
          Experience the comfort of <br className={classes.br} />
          receiving medical advice from your home.
          <br className={classes.br} /> Available 24/7.
        </p>
      </div>
      {loading ? (
        <Skeleton
          style={{ marginTop: "-2.3rem" }}
          animation="wave"
          width={230}
          height={100}
        />
      ) : (
        <>
        <Button
          className={classes.button}
          variant="contained"
          disabled={
            (plan?.length === 0 || plan === null || plan === undefined) &&
            category !== "hmo"
          }
          onClick={category !== "hmo" ? () => navigate('/start') :  openForm }
          style={{ backgroundColor: widgetColor || "transparent" , width: "22rem",
          whiteSpace: "nowrap"}}
        >
          Book Appointment
        </Button>

<Button
className={classes.button}
variant="contained"
disabled={
  (plan?.length === 0 || plan === null || plan === undefined) &&
  category !== "hmo"
}
onClick={category !== "hmo" ? () => navigate('/access') :  openForm }
style={{ backgroundColor: "#222", color:'#fff',     marginTop: "-1rem",     width: "22rem",
whiteSpace: "nowrap"}}
>
View Consultations
</Button>
        </>
      )}
      {(plan?.length === 0 || plan === null) && category !== "hmo" && (
        <Typography
          sx={{ color: "red", marginBottom: "1rem", fontSize: "0.9em" }}
        >
          No plan available. Please contact your provider
        </Typography>
      )}
    </div>
  );
};
