import React from 'react'
import { formatDateTime } from '../helperFunctions';
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Larr } from '../../assets/svgs/larr.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import SelectConfirm from '../doctors/selectConfirm';
import { useNavigate } from 'react-router-dom';

export default function Summary (
    {
        doc,
        time,
        channel,
        level, 
        expect, 
        aob,
        handleReset,
        selectedDate,
        fee,
        createConsultation,
        batches,
        loading,
        age,
        setAge,
        weight,
        setWeight,
        height,
        setHeight,
    }) {
    const navigate = useNavigate();
    const date = formatDateTime(selectedDate);

    return (
        <div className='bg-white mid:w-auto w-full mid:mt-3 mid:mb-6 rounded-lg mid:p-12 p-5'>
            <div className='flex flex-col font-euclid'>
                <div className="cursor-pointer flex w-full justify-between mb-6">
                    <div className='flex gap-2 items-center' onClick={() => navigate('/doctors')}>
                        <Larr />
                        <p className='text-[16px]'>Previous</p>
                    </div>
                    <Exit 
                        className="self-center" 
                        onClick={() => {
                            handleReset()
                            navigate('/');
                        }}
                    />
                </div>
                <h3 className='mt-0.5 font-medium text-2xl'>Appointment Summary</h3>
                <div className='mt-6 flex-col'>
                    <div className='flex gap-3'>
                        <div className='flex self-baseline'>
                            <img src={doc.picture} alt='doctor' className="w-16 h-16 rounded-full mt-1"/>
                        </div>
                        <div>
                            <h3 className="font-medium text-[25px]">{doc.lastName} {doc.firstName}</h3>
                            <p className="text-[16px]">{doc?.specialization  == 'Nil' ? doc?.cadre : doc?.specialization}</p>
                            {doc.rating ? 
                                <div className='flex items-center gap-1'>
                                    {[...Array(Math.floor(doc?.rating))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                    <p className="font-normal text-[11px]">
                                        {Number.isInteger(doc.rating) ? `${doc.rating}.0` : doc.rating}
                                    </p>
                                </div>
                            : ''}
                            <div className='hidden sm:flex items-center gap-1 mt-4 cursor-pointer flex-wrap'>
                                <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{date.formattedDate || "Now"}</div>
                                <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{time}</div>
                                <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">30mins</div>
                                <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{channel}</div>
                            </div>
                        </div>
                    </div>

                    <div className='flex sm:hidden items-center gap-1 mt-4 cursor-pointer flex-wrap'>
                        <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{date.formattedDate || "Now"}</div>
                        <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{time}</div>
                        <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">30mins</div>
                        <div className="text-[13px] font-medium py-2 px-6 border border-gray-50 rounded-[100px]">{channel}</div>
                    </div>
                </div>
                <div className='mt-10'>
                    <SelectConfirm 
                        level={level} 
                        expect={expect}
                        fee={fee} 
                        aob={aob}
                        handleReset={handleReset}
                        createConsultation={createConsultation}
                        batches={batches}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    )
}