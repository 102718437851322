import React from 'react'
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import { ReactComponent as Download } from '../../assets/svgs/download.svg';
import { Avatar } from '@mui/material';
import { formatDateTime, statusStyles, classNames, convertFirstLetterToUpperCase, stringAvatar } from '../helperFunctions';

export default function Selectest({setTest, data}) {
    const { formattedDate, formattedTime } = formatDateTime(data?.updatedAt);

    return (
        <div className='flex flex-col font-euclid h-full my-10'>
            <div className="cursor-pointer flex w-full justify-between items-center px-6">
                <h3 className='font-medium text-[25px]'>Test Summary</h3>
                <Exit onClick={() => setTest(false)} />
            </div>
            <div className='mt-6 px-6'>
                <div className='flex gap-3'>
                    <div className='flex self-center'>
                        {data?.doctorData?.picture ? (
                            <img src={data?.doctorData?.picture} alt='doctor' className="w-16 h-16 rounded-full"/>
                        ) : (
                            <Avatar {...stringAvatar(data?.doctorData?.lastName, data?.doctorData?.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/>    
                        )}
                    </div>
                    <div>
                        <h3 className="font-medium text-[25px]">{data?.doctorData?.lastName} {data?.doctorData?.firstName}</h3>
                        <p className="font-normal text-[16px]">{data?.doctorData?.specialization  == 'Nil' ?data?.doctorData?.cadre :data?.doctorData?.specialization}</p>
                        {data?.doctorData?.rating && (
                            <div className='flex items-center gap-1'>
                                {[...Array(Math.floor(data?.doctorData?.rating))].map((_, index) => (
                                    <Star key={index} />
                                ))}
                                <p className="text-[11px]">{Number.isInteger(data?.doctorData?.rating) ? `${data?.doctorData?.rating}.0` : data?.doctorData?.rating}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='mt-6 px-6'>
                <h3 className='font-medium text-[25px]'>healthtracka</h3>
                <p>Plot 6C, Providence Street, Lekki Phase 1 Lagos, 105102, Lekki, Lagos, 0814 489 1658</p>
            </div>
            <div className='mt-6 mb-10 px-6 flex flex-col gap-2'>
                <h5 className='font-medium'>Date & Time Issued</h5>
                <p>{formattedDate} at {formattedTime}</p>
                {/* <p className={classNames(
                    statusStyles[convertFirstLetterToUpperCase(data?.status)],
                    'px-2 rounded-[10px] w-[102px]'
                )}>
                    {convertFirstLetterToUpperCase(data?.status)}
                </p> */}
            </div>
            <div className='w-full bg-secondary px-6 py-10 flex-1'>
                <h3 className='font-medium text-[25px]'>Test Details</h3>
                <p>Test ID: {data?._id}</p>
                <div className='mt-6'>
                    {data?.tests?.map((t) => (
                        <div className='flex cursor-pointer bg-white px-12 py-6 border border-gray-700 rounded-lg shadow-inner justify-between'>
                            <h5>{t?.name}</h5>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}