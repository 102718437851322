import React, { useState, useContext, useEffect } from 'react'
import Chip from '@mui/material/Chip';
import { ReactComponent as Exit } from '../../assets/svgs/exi.svg';
import { ReactComponent as Exits } from '../../assets/svgs/exi2.svg';
import { ReactComponent as Menus } from '../../assets/svgs/menu.svg';
import { ReactComponent as Search } from '../../assets/svgs/search.svg';
import { ReactComponent as Empty } from '../../assets/svgs/error.svg';
import Drawers from '../drawers';
import Filter from '../filter/filter';
import Tabledashboard from '../table/table';
import Menu from '../menu/menu';
import { TestContext } from '../../context/testContext';

export default function Test(
  {
    setMenu, 
    menu, 
    activeItem, 
    setActiveItem, 
    openProfile, 
    setOpenProfile,
    setFilterTDoctor,
    setFilterTTest,
    testLoading,
    profileLoading
  }
) {
  const { test, test2 } = useContext(TestContext);
  const [open, setOpen] = useState(false);
  const [chipTest, setChipTest] = useState(false);
  const [chipDataTest, setChipDataTest] = useState([]);
  const [filteredDoctorTest, setFilteredDoctorTest] = useState([]);
  const [filteredTest, setFilteredTest] = useState([]);
  const tableHead = [ 'Test Name', 'Doctor Name', 'Date', 'Action']
  const tests = 'test';
  const testData = test?.referral;
  const storedData = sessionStorage.getItem('chipTestData');

  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setChipDataTest(parsedData);
      setChipTest(parsedData.length > 0);
    }
  }, [storedData]);

  useEffect(() => {
    if (testData) {
      const fetchData= () => {
        const extractedDoctors = extractDoctors(testData);
        const extractedTests = extractTests(testData);
        setFilteredDoctorTest(extractedDoctors);
        setFilteredTest(extractedTests);
      };
      fetchData();
    }
  }, [testData]);

  const extractDoctors = (data) => {
    const doctors = {};
    for (const item of data) {
      const { doctorData } = item;
      if (doctorData && doctorData._id && doctorData.firstName && doctorData.lastName) {
        const { _id, firstName, lastName } = doctorData;
        if (!doctors[_id]) {
          doctors[_id] = { _id, firstName, lastName };
        }
      }
    }
    const filterDoctor = Object.values(doctors); 
    return filterDoctor;
  };

  const extractTests = (data) => {
    const testNames = data.flatMap((data) =>
      data.tests.map((test) => test.name).filter(Boolean)
    );

    return [...new Set(testNames)];
  };

  const handleChipDelete = () => {
    setChipDataTest([]);
    setChipTest(false);
    sessionStorage.removeItem('chipTestData');
    setFilterTDoctor(''); 
    setFilterTTest('');    
  }

  const handleRemoveChip = (c) => {
    setChipDataTest((prevData) => {
      const updatedData = prevData.filter((chip) => {
          return JSON.stringify(chip) !== JSON.stringify(c);
      });
  
      if (updatedData.length === 0) {
          sessionStorage.removeItem('chipTestData');
      } else {
          sessionStorage.setItem('chipTestData', JSON.stringify(updatedData));
      }
  
      const remainingDoctor = updatedData.find((chip) => chip.doctor)?.doctor;
      const remainingTest = updatedData.find((chip) => chip.test)?.test;
  
      setFilterTDoctor(remainingDoctor || '');
      setFilterTTest(remainingTest || '');
      
      return updatedData;
    });
  };  

  return (
    <>
      <div className='flex items-center gap-4'>
        <Menus className='desktop:hidden' onClick={() => setMenu(!menu)} />
        <h3 className='font-medium text-[25px]'>Tests</h3>
      </div>
      {test2?.referral?.length === 0 ? (
        <div className='flex justify-center h-full w-full'>
          <div className='flex flex-col items-center justify-center h-[91%] w-2/5'>
            <Empty />
            <h3 className='-mt-6 text-[25px] font-medium font-euclid'>No Tests</h3>
            <p className='text-center font-euclid text-gray-400'>Tests are automatically added when a doctor recommends one.</p>
          </div>
        </div>
      ) : (
        <>
          <div className='mt-6 tab:mt-5 laptop:mt-8 mb-8 md:mb-6 flex flex-col tab:flex-row gap-6 tab:gap-4 tab:items-center'>
            {/* <div className='flex'>
              <Search className='absolute mx-6 my-3'/>
              <input placeholder='Search' className='focus:outline-none placeholder:text-gray-700 w-full tab:w-[362px] py-3 pr-6 pl-14 border rounded-lg border-gray-50'/>
            </div> */}
            {/* <p className='font-medium text-primary text-[13px] cursor-pointer hover:opacity-75 transition ease-out' onClick={() => setOpen(!open)}>Filters</p> */}
          </div>

          {chipTest && (
            <div className='flex flex-wrap my-4 gap-2 filter-chip'>
              {chipDataTest.map((c) => {
                const label = c.name;
                const value = c[Object.keys(c)[0]];

                return (
                  <Chip
                    key={label}
                    variant="outlined"
                    label={label}
                    icon={<Exit onClick={() => handleRemoveChip(c)} />}
                    sx={{
                      borderColor: '#EDEDED',
                      flexDirection: 'row-reverse',
                      fontWeight: 500,
                      fontSize: '13px',
                      fontFamily: 'Euclid Circular A',
                      cursor: 'pointer',
                    }}
                  />
                );
              })}
              {chipDataTest.length > 1 && (
                <Chip
                  variant='filled'
                  label='Clear all'
                  onClick={handleChipDelete}
                  icon={<Exits />}
                  sx={{
                    backgroundColor: '#3E5EA9',
                    color: 'white',
                    flexDirection: 'row-reverse',
                    fontWeight: 500,
                    fontSize: '13px',
                    fontFamily: 'Euclid Circular A',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          )}
          
          <Tabledashboard tableHead= {tableHead} profileLoading={profileLoading} index={tests} loading={testLoading}/>
          
          <Drawers openDrawer={open} setOpenDrawer={setOpen}>
            <Filter 
              setOpen={setOpen}
              filter='test'
              setFilterTDoctor={setFilterTDoctor}
              setFilterTTest={setFilterTTest}
              setChipTest={setChipTest} 
              chipDataTest={chipDataTest} 
              setChipDataTest={setChipDataTest} 
              filteredDoctorTest={filteredDoctorTest}
              filteredTest={filteredTest}
            />
          </Drawers>
        </>
      )}
      <Drawers openDrawer={menu} setOpenDrawer={setMenu} side="left" width='298px' minwidth='298px'>
        <div className='px-8 pt-10 h-full'>
          <Menu activeItem={activeItem} setActiveItem={setActiveItem} menu={menu} setMenu={setMenu} openProfile={openProfile} setOpenProfile={setOpenProfile}/>
        </div>
      </Drawers>
    </>
  )
}
