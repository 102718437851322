import { createContext, useState } from "react";

export const ConsultationContext = createContext();

export const ConsultationProvider = ({children}) => {
    const [consultation, setConsultation] = useState(null);
    const [consult, setConsult] = useState(null);
    const [consult2, setConsult2] = useState(null);
        
    const fetchConsultation = () => {
        const storedConsultation = sessionStorage.getItem("consultation");
        if (storedConsultation !== undefined && storedConsultation !== null && storedConsultation !== "undefined") {
            try {
                const parsedConsultation = JSON.parse(storedConsultation);
                setConsultation(parsedConsultation.getConsultations);
            } catch (error) {
                console.error("Error parsing stored consultation:", error);
            }
        }
    }
    
    const fetchConsult = () => {
        const storedConsultation = sessionStorage.getItem("consult");
        if (storedConsultation !== undefined && storedConsultation !== null && storedConsultation !== "undefined") {
            try {
                const parsedConsultation = JSON.parse(storedConsultation);
                setConsult(parsedConsultation.getConsultations);
            } catch (error) {
                console.error("Error parsing stored consultation:", error);
            }
        }
    }
    
    const fetchConsult2 = () => {
        const storedConsultation = sessionStorage.getItem("consult2");
        if (storedConsultation !== undefined && storedConsultation !== null && storedConsultation !== "undefined") {
            try {
                const parsedConsultation = JSON.parse(storedConsultation);
                setConsult2(parsedConsultation.getConsultations);
            } catch (error) {
                console.error("Error parsing stored consultation:", error);
            }
        }
    }

    return (
        <ConsultationContext.Provider value={{consultation, fetchConsultation, consult, fetchConsult, fetchConsult2, consult2}}>
            {children}
        </ConsultationContext.Provider>
    )
}