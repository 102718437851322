import React from 'react'

export default function Tablemodal({setReschedule, setDraw, draw, modal}) {    
    const handleJoinConsultation = (id) => {
        window.open(`https://staging-consultation.heala.io/${id}`, "_blank");
    }  

    return (
        <>
            {(modal?.status === 'declined' || modal?.status === 'ongoing' || modal?.status === 'missed' || modal?.status === 'cancelled' || modal?.status === 'completed') ? '' :
                (
                    <div className='absolute gap-2 flex-col shadow-[0_10px_20px_rgba(0,0,0,0.1)] -mt-5 right-[10%] bg-white py-6 px-3 rounded-lg w-[180px] h-[52px] flex justify-center items-center cursor-pointer'>
                        <p 
                            onClick={() => {
                                setReschedule(false);
                                setDraw(!draw);
                            }}
                            className='text-gray-400'
                        >Reschedule</p>
                        {modal?.status === 'accepted' && (
                            <p 
                                onClick={() => {
                                    setReschedule(false);
                                    handleJoinConsultation(modal?._id)
                                }}
                                className='text-gray-400'
                            >Join Consultation</p>
                        )}
                    </div>
                )}
        </>
    )
}
