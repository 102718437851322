import { makeStyles } from "@mui/styles";

export const useDashboardstyles = makeStyles(() => ({
     root: {
          display: 'flex',
          minHeight: '100vh',
          width: '100%',
          fontFamily: 'Euclid Circular A'
     },
}));
