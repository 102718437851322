import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { UPDATE_PROFILE } from '../../api/graphQL/query';
import useAlert from '../../hooks/useAlert';
import { ProfileContext } from '../../context/profileContext';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { parseInteger } from '../helperFunctions';

const useStyles = makeStyles(() => ({
    Button: {
      padding: "16px 24px !important",
      borderRadius: "8px !important",
      textTransform: "capitalize !important",
      backgroundColor: '#3E5EA9 !important',
      fontSize: "13px !important",
      fontWeight: "500 !important",
      fontFamily: "Euclid Circular A !important",
      width: '100%',
      color: '#EDEDED !important',
      boxShadow: 'none !important',
      transition: '.2s ease-out',
      '&:hover': {
        opacity: 0.75
      },
    },
}));  

export default function Selectprofile({ picture, setOpenProfile }) {
    const classes = useStyles();
    const {displayAlert} = useAlert();
    const { profile, fetchProfile } = useContext(ProfileContext);

    const [phone, setPhone] = useState("");
    const [genotype, setGenotype] = useState("");
    const [blood, setBlood] = useState("");
    const [height, setHeight] = useState(null);
    const [weight, setWeight] = useState(null);

    const authToken = localStorage.getItem('authToken');
    const id = profile?.profiles?.data[0]?._id;
    const prof = profile?.profiles?.data[0];
    
    const handleHeightChange = (event) => {
        const value = parseInteger(event.target.value);
        setHeight(value);
    };
    
    const handleWeightChange = (event) => {
        const value = parseInteger(event.target.value);
        setWeight(value);
    };

    const [updateProfileMutation, { loading, error }] = useMutation(UPDATE_PROFILE);
    
    const updateProfile = async () => {
        if (isNaN(phone) || !Number.isInteger(Number(phone))) {
            displayAlert("error", "Invalid phone number!");
            return;
        }   

        setPhone("")
        setGenotype("")
        setBlood("")
        setHeight(null)
        setWeight(null)
        
        try {
            const updateProfileResponse = await updateProfileMutation({
                variables: {
                    id: id,
                    firstName: prof?.firstName,
                    lastName: prof?.lastName,
                    gender: prof?.gender,
                    phoneNumber: phone || prof?.phoneNumber,
                    image: picture || prof?.image || "https://heala-partners-2qzf8yrm7-heala-io.vercel.app/static/media/logo.723375eb.svg",
                    height: height || prof?.height || 163,
                    weight: weight || prof?.weight || 52,
                    bloodGroup: blood || prof?.bloodGroup || "O",
                    genotype: genotype || prof?.genotype || "AA",
                },
                context: {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            });

            displayAlert('success', 'Profile updated successfully!');
            setOpenProfile(false);
            window.location.reload(false);
        } catch (error) {
            displayAlert('error', 'Error updating profile!');
            console.error(error);      
        }
    }

    return (
        <div className='mt-10 flex flex-col w-full justify-center px-3'>
            <div className="flex gap-4 mb-6">
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">First Name</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField 
                            id="outlined-basic" 
                            label="" 
                            value={prof?.firstName} 
                            variant="outlined" 
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                </div>
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Last Name</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField 
                            id="outlined-basic" 
                            label="" 
                            value={prof?.lastName} 
                            variant="outlined" 
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="flex gap-4 mb-6">
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Phone Number</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField id="outlined-basic" label="" placeholder={prof?.phoneNumber} value={phone} onChange={(e) => setPhone(e.target.value)} variant="outlined" />
                    </FormControl>
                </div>
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Gender</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField 
                            id="outlined-basic" 
                            label="" 
                            value={prof?.gender} 
                            variant="outlined" 
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="flex gap-4 mb-6">
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Genotype</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField id="outlined-basic" label="" placeholder={prof?.genotype?.toString() || 'AA'} value={genotype} onChange={(e) => setGenotype(e.target.value)} variant="outlined" />
                    </FormControl>
                </div>
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Blood Group</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField id="outlined-basic" label="" placeholder={prof?.bloodGroup?.toString()} value={blood} onChange={(e) => setBlood(e.target.value)} variant="outlined" />
                    </FormControl>
                </div>
            </div>
            <div className="flex gap-4 mb-6">
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Height</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField id="outlined-basic" placeholder={prof?.height?.toString()} label="" value={height} onChange={handleHeightChange} variant="outlined" />
                    </FormControl>
                </div>
                <div className='w-full'>
                    <p className="font-normal text-[16px] text-gray-400 mb-1">Weight</p>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <TextField id="outlined-basic" label="" placeholder={prof?.weight?.toString()} value={weight} onChange={handleWeightChange} variant="outlined" />
                    </FormControl>
                </div>
            </div>

            <div className='my-6'>
                <Button variant='contained' className={classes.Button} onClick={updateProfile}>
                    {loading ? 
                        (<CircularProgress
                            style={{ width: 20, height: 20, color: "#fff" }}
                        /> )
                    : 'Update Profile'}
                </Button>
            </div>
        </div>
    )
}
