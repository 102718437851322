import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../Button";
import { OtpInput, Input } from "../Input";
import useAlert from "../../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { COMPLETE_PASSWORD_RESET, RESEND_OTP, VERIFY_EMAIL } from "../../api/graphQL/query";
import { CircularProgress } from "@mui/material";
import eyeclosed from "../../assets/icons/eye-closed.svg";
import { ReactComponent as Previous } from "../../assets/svgs/arrow.svg";

export const OtpForm = () => {
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { email, user } = state;
  const { displayAlert } = useAlert();
  const [otp, setOtp] = useState();
  const [countdown, setCountdown] = useState(53);
  const [resetCountdown, setResetCountdown] = useState(false)
  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState("");
  const passwordInputType = isPassword ? "password" : "text";
  const passwordInputType2 = isConfirmPassword ? "password" : "text";

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      setResetCountdown(!resetCountdown);
    }
  }, [countdown]);

  const [verifyEmailMutation, { loading, error }] = useMutation(VERIFY_EMAIL);
  const [resendOtpMutation, { loading:otpLoading, error:errorOtp }] = useMutation(RESEND_OTP);
  const [resetPasswordMutation, { loading:resetLoading, error:errorReset }] = useMutation(COMPLETE_PASSWORD_RESET);

  const handleSubmit = () => {
    if (user === 'signup') {
      verifyEmail()
    } else {
      resetPassword()
    }
  }

  const resetPassword = async () => {
    setPasswordError("");

    if (!password || !confirmPassword) {
      setPasswordError("Please enter your password.");
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords are not the same.");
      return;
    }

    try {
      setPassword("");
      setConfirmPassword("");

      const resetResponse = await resetPasswordMutation({
        variables: {
          email: email,
          password: password,
          otp: otp
        }
      })
      inputRefs.current.forEach((inputRef) => (inputRef.value = ''));
      inputRefs.current[0].focus();
      navigate("/resetsuccessful");
    } catch(error) {
      displayAlert("error", "Invalid verification code");
      inputRefs.current.forEach((inputRef) => (inputRef.value = ''));
      inputRefs.current[0].focus();
      console.error(error);
    }
  }
  
  const verifyEmail = () => {
    verifyEmailMutation({
      variables: {
        email: email,
        otp: otp
      }
    })
      .then((response) => {
        console.log(response);
        inputRefs.current.forEach((inputRef) => (inputRef.value = ''));
        inputRefs.current[0].focus();
        displayAlert("success", "registration successful.");
        setTimeout(() => {
          navigate("/bookconsultation");
        }, 300)
      })
      .catch((error) => {
        displayAlert("error", "Invalid verification code");
        inputRefs.current.forEach((inputRef) => (inputRef.value = ''));
        inputRefs.current[0].focus();
        console.error(error);
      });
  }

  const resendOtp = () => {
    resendOtpMutation({
      variables: {
        email: email,
      }
    })
    .then((response) => {
      setResetCountdown(false);
      console.log(response);
      setCountdown(53);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  return (
    <div>
      <div className="pt-5">
        <Previous onClick={() => user === 'forgot' ? navigate('/forgotpassword') : navigate('/signup')} />
      </div>
      <div className="w-full h-full flex flex-col justify-center">
        <h1 className="font-semibold text-black text-[40px] leading-[50.72px] m-0">
          Verify Email
        </h1>
        <p className="text-base leading-[20.29px] m-0 mt-2">
          Kindly Provide us with the OTP we’ve sent to your email address
        </p>
        <form className="heala-otp-form mt-12">
          <div className="flex items-end space-x-4 mb-8">
            {Array(6)
              .fill()
              .map((_, idx) => {
                return (
                  <OtpInput
                    key={idx}
                    ref={(el) => (inputRefs.current[idx] = el)}
                    label={idx === 0 ? "OTP" : ""}
                    placeholder="-"
                    onChange={(e) => {
                      const value = e?.target?.value;
                      if (value === "") return;
                      if (idx === 5 && value === "") return;
                      inputRefs?.current[idx + 1]?.focus();
                      const inputValues = inputRefs.current?.map((value) => value?.value);
                      const otpString = inputValues.filter(Boolean).join("");
                      const otpNumber = parseInt(otpString, 10);
                      setOtp(otpNumber);
                    }}
                    fullWidth
                  />
                );
              })}
          </div>
          {user === 'forgot' && (
            <div className="space-y-6 mb-[62px]">
              <Input
                type={passwordInputType}
                label="New Password"
                placeholder="New Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errormessage={passwordError}
                endAdornment={
                  <button
                    type="button"
                    className="cursor-pointer"
                    onClick={() => setIsPassword(!isPassword)}
                  >
                    <img src={eyeclosed} alt="eye closed" />
                  </button>
                }
              />
              <Input
                type={passwordInputType2}
                label="Confirm New Password"
                placeholder="Confirm New Password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                errormessage={passwordError}
                endAdornment={
                  <button
                    type="button"
                    className="cursor-pointer"
                    onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                  >
                    <img src={eyeclosed} alt="eye closed" />
                  </button>
                }
              />
            </div>
          )}
          {resetCountdown ? (
            <Button
              fullWidth
              onClick={() => {
                resendOtp();
              }}
            >
              Resend Otp
            </Button>
          ) : (
            <Button
              fullWidth
              onClick={handleSubmit}
            >
              {loading || resetLoading ? (
                <CircularProgress
                  style={{ width: 20, height: 20, color: "#fff" }}
                />
              ) : (
                "Verify"
              )}
            </Button>
          )}
          {countdown >= 0 && (
            <p className="text-center text-primary leading-[20.29px] m-0 mt-[14px]">
              OTP expiring in {countdown}s
            </p>
          )}
        </form>
      </div>
    </div>
  );
};
