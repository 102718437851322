import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FIND_PROFILES, GET_CONSULTATIONS,GET_CONSULTATIONST, GET_PRESCRIPTIONS, GET_REFERRALS, GET_CONSULTATION,GET_CONSULTATIONT,GET_SERVER_TIME} from "../../api/graphQL/query";
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ProfileContext } from '../../context/profileContext';
import { ConsultationContext } from '../../context/consultationContext';
import { PrescriptionContext } from '../../context/prescriptionContext';
import { TestContext } from '../../context/testContext';
import { useDashboardstyles } from './styles';
import Leftbar from '../../components/leftbar/leftbar';
import Fullbar from '../../components/fullbar/fullbar';
import Consult from '../../components/consult/consult';
import Board from '../../components/board/board';
import Prescribe from '../../components/prescribe/prescribe';
import Test from '../../components/test/test';
import Exit from '../../components/exit/exit';
import Loader from '../../components/Loader';
import SkeletonLoader from '../../components/table/skeletonLoader';

const Dashboard = () => {
     const classes = useDashboardstyles();
     const location = useLocation();
     const pathname = location.pathname;
     const navigate = useNavigate();
     const { reload } = location.state || {};
     const { fetchProfile } = useContext(ProfileContext);
     const { fetchConsultation, fetchConsult, fetchConsult2 } = useContext(ConsultationContext);
     const { fetchPrescription } = useContext(PrescriptionContext);
     const { fetchTest, fetchTest2 } = useContext(TestContext);
     const [activeItem, setActiveItem] = useState('dashboard');
     const [openProfile, setOpenProfile] = useState(false);
     const [menu, setMenu] = useState(false);
     const [page, setPage] = useState(1);
     const [filterCDoctor, setFilterCDoctor] = useState('');
     const [filterCChannel, setFilterCChannel] = useState('');
     const [filterCStatus, setFilterCStatus] = useState('');
     const [filterTDoctor, setFilterTDoctor] = useState('');
     const [filterTTest, setFilterTTest] = useState('');
     const authId = localStorage.getItem('authId');
     const authToken = localStorage.getItem('authToken');

     useEffect(() => {
          if (reload) {
               window.location.reload(false);
               navigate({ state: { reload: false } });
          }
     }, [reload]);

     useEffect(() => {
          if (pathname === '/dashboard') {
            setActiveItem('dashboard');
          } else if (pathname === '/consultations') {
            setActiveItem('consultations');
          } else if (pathname === '/prescription') {
            setActiveItem('prescription');
          } else if (pathname === '/tests') {
            setActiveItem('test');
          } else if (pathname === '/signout') {
            setActiveItem('exit');
          }
     }, [location]);

     const httpLink = createHttpLink({
        uri: process.env.REACT_APP_API_BASE_URL,
     });

     const authLink = setContext((_, { headers }) => {
          return {
               headers: {
                    ...headers,
                    authorization: authToken ? `Bearer ${authToken}` : '',
               },
          };
     });

     const client = new ApolloClient({
          link: authLink.concat(httpLink),
          cache: new InMemoryCache(),
     });

     const { loading: profileLoading, error: profileError, data: profileData } = useQuery(FIND_PROFILES, {
          skip: !authToken && !authId,
          variables: { dociId: authId },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });

     const { loading: consultationLoading, error: consultationError, data: consultationData } = useQuery(GET_CONSULTATIONST, {
          skip: !authToken || !profileData?.profiles?.data[0]?._id,
          variables: {
               filterBy: {
                    //paid: "true",
                    patient: profileData?.profiles?.data[0]?._id,
                    ...(filterCDoctor && { doctor: filterCDoctor }),
                    ...(filterCChannel && { contactMedium: filterCChannel }),
                    ...(filterCStatus && { status: filterCStatus })
               },
               page: page
          },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });

     //, type: "scheduled", paid: "true"
             
     const { loading: consultLoading , error: consultError, data: consultData } = useQuery(GET_CONSULTATIONT, {
          skip: !authToken || !profileData?.profiles?.data[0]?._id,
          variables: { id: profileData?.profiles?.data[0]?._id },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });
     
     const { loading: consultLoading2 , error: consultError2, data: consultData2 } = useQuery(GET_CONSULTATIONST, {
          skip: !authToken || !profileData?.profiles?.data[0]?._id,
          variables: {
               filterBy: {
                    //paid: "true",
                    patient: profileData?.profiles?.data[0]?._id,
               },
               page: 1
          },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });
     
     const { loading: prescriptionLoading , error: prescriptionError, data: prescriptionData } = useQuery(GET_PRESCRIPTIONS, {
          skip: !authToken || !profileData?.profiles?.data[0]?._id,
          variables: { id: profileData?.profiles?.data[0]?._id },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });
     
     const { loading: testLoading , error: testError, data: testData } = useQuery(GET_REFERRALS, {
          skip: !authToken || !profileData?.profiles?.data[0]?._id,
          variables: {
               filterBy: {
                    patient: profileData?.profiles?.data[0]?._id,
                    ...(filterTDoctor && { doctor: filterTDoctor }),
                    ...(filterTTest && { test: filterTTest }),
               },
               page: page
          },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });
     
     const { loading: testLoading2 , error: testError2, data: testData2 } = useQuery(GET_REFERRALS, {
          skip: !authToken || !profileData?.profiles?.data[0]?._id,
          variables: {
               filterBy: {
                    patient: profileData?.profiles?.data[0]?._id,
               },
               page: 1
          },
          context: {
               headers: {
                    Authorization: `Bearer ${authToken}`
               }
          }
     });

     const { error:serErr, data:serData, loading:serLoad } = useQuery(GET_SERVER_TIME);

     const getServerTime = serData?.getServerTime?.timeLagosJsDate;
     localStorage.setItem("getServerTime", getServerTime)

     useEffect(() => {
          localStorage.setItem("profile", JSON.stringify(profileData));
          fetchProfile();
          sessionStorage.setItem("consultation", JSON.stringify(consultationData));
          fetchConsultation();
          sessionStorage.setItem("consult", JSON.stringify(consultData));
          fetchConsult();
          sessionStorage.setItem("consult2", JSON.stringify(consultData2));
          fetchConsult2();
          sessionStorage.setItem("prescription", JSON.stringify(prescriptionData));
          fetchPrescription();
          sessionStorage.setItem("test", JSON.stringify(testData));
          fetchTest();
          sessionStorage.setItem("test2", JSON.stringify(testData2));
          fetchTest2();
     }, [profileData, consultationData, prescriptionData, testData, testData2, consultData, consultData2]);   
     
     return (
          <ApolloProvider client={client}>
               <div className={classes.root}>
                    <>
                         <Leftbar loading={profileLoading} openProfile={openProfile} setOpenProfile={setOpenProfile} setActiveItem={setActiveItem} activeItem={activeItem}/>
                         {pathname === '/dashboard' && (
                              <Fullbar>
                                   <Board 
                                        menu={menu} 
                                        setMenu={setMenu} 
                                        openProfile={openProfile} 
                                        setOpenProfile={setOpenProfile}
                                        setActiveItem={setActiveItem}
                                        activeItem={activeItem}
                                        profileLoading={profileLoading}
                                        consultLoading={consultationLoading}
                                        prescribeLoading={prescriptionLoading}
                                        testLoading={testLoading}
                                   />
                              </Fullbar>
                         )}
                         {pathname === '/consultations' && (
                              <Fullbar>
                                   <Consult 
                                        menu={menu} 
                                        page={page} 
                                        setPage={setPage} 
                                        setMenu={setMenu} 
                                        openProfile={openProfile} 
                                        setOpenProfile={setOpenProfile} 
                                        setFilterCDoctor={setFilterCDoctor}
                                        setFilterCChannel={setFilterCChannel}
                                        setFilterCStatus={setFilterCStatus}
                                        setActiveItem={setActiveItem}
                                        activeItem={activeItem}
                                        consultationLoading={consultationLoading}
                                        profileLoading={profileLoading}
                                   />
                              </Fullbar>
                         )}
                         {pathname === '/prescription' && (
                              <Fullbar>
                                   <Prescribe 
                                        menu={menu} 
                                        setMenu={setMenu} 
                                        openProfile={openProfile} 
                                        setOpenProfile={setOpenProfile}
                                        setActiveItem={setActiveItem}
                                        activeItem={activeItem}
                                        prescriptionLoading={prescriptionLoading}
                                        profileLoading={profileLoading}
                                   />
                              </Fullbar>
                         )}
                         {pathname === '/tests' && (
                              <Fullbar>
                                   <Test 
                                        menu={menu} 
                                        setMenu={setMenu} 
                                        openProfile={openProfile} 
                                        setOpenProfile={setOpenProfile} 
                                        setActiveItem={setActiveItem}
                                        activeItem={activeItem}
                                        setFilterTTest={setFilterTTest}
                                        setFilterTDoctor={setFilterTDoctor}
                                        testLoading={testLoading}
                                        profileLoading={profileLoading}
                                   />
                              </Fullbar>
                         )}
                         {pathname === '/signout' && (
                              <Fullbar>
                                   <Exit 
                                        menu={menu} 
                                        setMenu={setMenu} 
                                        openProfile={openProfile} 
                                        setOpenProfile={setOpenProfile} 
                                        setActiveItem={setActiveItem}
                                        activeItem={activeItem}
                                   />
                              </Fullbar>
                         )}
                    </>
               </div>
          </ApolloProvider>
     );
};

export default Dashboard;