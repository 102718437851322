import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchAPI } from '../../api/axios/axios';
import { useQuery } from '@apollo/client';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getAvailableTimeSlot } from '../helperFunctions';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button, TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Chip } from "@mui/material";
import { GET_ISSUES,GET_SERVER_TIME } from '../../api/graphQL/query';
import useAlert from '../../hooks/useAlert';
import { Input } from '../Input';
import { PhoneDropdown } from '../phone';
import ReactTagAutocomplete from 'react-tag-autocomplete';

const useStyles = makeStyles(() => ({
    cancelButton: {
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        borderColor: "#3E5EA9 !important",
        color: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
        },
    },
    submitButton: {
        padding: "18px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
        },
    },
}));

export default function SelectField(
    {
        time,
        setTime,
        setEmail,
        channel,
        setChannel,
        email,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phone,
        setPhone,
        gender,
        setGender,
        symptoms,
        setSymptoms,
        level,
        setLevel,
        expect,
        setExpect,
        aob,
        setAob,
        handleReset,
        doc,
        setFee,
        batches,
        setBatches,
        selectedDate,
        createConsultation,
        setSelectedDate
    }) {
    const classes = useStyles();
    const { displayAlert } = useAlert();
    const navigate = useNavigate();
    const { GET_DOCTOR_AVAILIBILITY_FOR_DATE } = useFetchAPI();
    const { error, data, loading: issuesLoading } = useQuery(GET_ISSUES);
    const issues = data?.getIssues?.issues;

    const [loading, setLoading] = useState(false);
    const [filteredIssues, setFilteredIssues] = useState([]);
    const [date, setDate] = useState("");
    const [availableTime, setAvailableTime] = useState([]);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const id = doc._id;
    const fee = doc.fee;

    useEffect(() => {
        if (id) {
            const todayDate = dayjs().format('YYYY-MM-DD');
            setSelectedDate(todayDate);
            setDate(todayDate);
            getDoctorsTimesByDate(todayDate, id);
        }
        return;
    }, [id])

    const handleChipDelete = (chipToDelete) => () => {
        setBatches(batches.filter((chip) => chip !== chipToDelete))
    }

    const handlegetDoctors = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        setDate(formattedDate);
        getDoctorsTimesByDate(formattedDate, id);
    };

    const getDoctorsTimesByDate = async (date, docId) => {
        try {
            const res = await GET_DOCTOR_AVAILIBILITY_FOR_DATE(date, docId);
            setAvailableTime(res?.data?.data?.times || []);
        } catch (error) {
            displayAlert("error", "Error while trying to get available times.");
            console.error("Error from getDoctorsTimesByDate function:", error);
        }
    };

    const { error:serErr, data:serData, loading:serLoad } = useQuery(GET_SERVER_TIME);

    const getServerTime = serData?.getServerTime?.timeLagosJsDate;
    console.log(serData,"getServerTime")

    const currentDateL = getServerTime?.split('').splice(0,15).join(''); 
    const currentDate = getServerTime
    var inputDate = date
    var dateComponents = inputDate.split("-");
    
    var year = dateComponents[0];
    var month = parseInt(dateComponents[1]) - 1; // JavaScript uses zero-based months
    var day = dateComponents[2];
    
    var months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    
    var dateObj = new Date(year, month, day);
    var formattedDate = dateObj.toString().substr(0, 3) + " " + months[month] + " " + day + " " + year;    

    console.log(currentDateL,"currentDatexyz",formattedDate)

    const selectedSlot = currentDateL == formattedDate
    ? getAvailableTimeSlot(availableTime, currentDate)
    : availableTime;

    const handleSymptomChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setSymptoms("");
            setFilteredIssues([]);
            return;
        }
        
        setSymptoms(value);
        
        const symptomStringCase = value[0].toUpperCase() + value.slice(1).toLowerCase();
        setSymptoms(symptomStringCase);

        const filtered = issues.filter((issue) =>
            issue.Name.includes(symptomStringCase)
        );
        setFilteredIssues(filtered);
    };

    const handleSymptomClick = (item) => {
        setSymptoms(item.Name);
        setFilteredIssues([]);
        setSymptoms("");
        setBatches((batches) => [item.Name, ...batches]);
    };

    const handleSymptomClick1 = (item) => {
        setSymptoms(item);
        setFilteredIssues([]);
        setSymptoms("");
        setBatches((batches) => [item, ...batches]);
        console.log(batches)
    };
    
    const handleBatchRemove = (id) => {
        setBatches((batches) => [...batches.filter((_, idx) => idx !== id)]);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (batches.length === 0 && symptoms) {
            setBatches((batches) => [symptoms, ...batches])
        }

        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setPhoneError("");

        if (!time) {
            displayAlert('error', 'Choose an available time slot');
            return;
        } else if (!channel) {
            displayAlert('error', 'Select a contact medium');
            return;
        } else if (!expect) {
            displayAlert('error', 'Select a first notice period');
            return;
        } else if (!level) {
            displayAlert('error', 'Select a discomfort level');
            return;
        } else if (!firstName) {
            setFirstNameError("Please enter your first name.");
            return;
        } else if (!lastName) {
            setLastNameError("Please enter your last name.");
            return;
        } else if (!email) {
            setEmailError("Please enter your email address.")
            return;
        } else if (!gender) {
            displayAlert('error', 'Please select your gender.');
            return;
        } else if (!phone) {
            setPhoneError("Please enter your phone number.")
            return;
        } else if (batches.length === 0 && !symptoms) {
            displayAlert('error', 'Select at least one symptom');
            return;
        } else {
            setLoading(true);
            localStorage.setItem("userEmail", email);

            try {
                await createConsultation();
            } catch (error) {
                console.error("Error creating consultation:", error);
            }

            setLoading(false);
        }
    }

    const getFee = async () => {
        setLoading(true);
        try {
            setFee(fee);
            setLoading(false);
            navigate('consultsummary');
        } catch (error) {
            console.error("No fee:", error);
        }
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
  
    const handleSymptomAdd = (newSymptom) => {
        if (newSymptom) {
            setBatches((prevBatches) => [...prevBatches, newSymptom]);
            setSymptoms("");
        }
    };
    
    return (
        <form onSubmit={handleSubmit} className='h-full flex flex-col'>
            <h3 className="font-medium text-[18px]">Symptom Information</h3>
            <p className="font-normal text-[13px]">Tell us what the problem is</p>
            <div className='mt-6 flex flex-col gap-3'>
                <div>
                    <div className='chip-input-container'>
                        <div className="chips-input font-euclid">
                            {batches.map((chip, index) => (
                                <Chip
                                    key={index}
                                    label={chip}
                                    onDelete={handleChipDelete(chip)}
                                    className="chip"
                                />
                            ))}
                            <TextField
                                onChange={handleSymptomChange}
                                variant="standard"
                                value={symptoms}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        setSymptoms(symptoms);
                                        setFilteredIssues([]);
                                        handleSymptomClick1(e.target.value);
                                    }
                                }}
                                // onKeyPress={handleInputKeyPress}
                                fullWidth
                                InputProps={{disableUnderline: true}}

                            />
                        </div>
                    </div>
                    {filteredIssues.length > 0 && (
                        <div
                            className="heela-symptoms-container absolute z-[9999]"
                            style={{ padding: "1rem" }}
                        >
                            {filteredIssues.map((item) => (
                                <div
                                    key={item.ID}
                                    className="heela-symptoms-option"
                                    onClick={() => handleSymptomClick(item)}
                                >
                                    {item.Name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div>
                    <p className="font-normal text-[13px] text-gray-400 mb-1">What is your discomfort level?</p>
                    <FormControl sx={{ mb: 1, minWidth: 120 }}>
                        {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={level}
                            // label="Heala"
                            onChange={(e) => setLevel(e.target.value)}
                        >
                            <MenuItem value="None">None</MenuItem>
                            <MenuItem value="Mild">Mild</MenuItem>
                            <MenuItem value="Medium">Medium</MenuItem>
                            <MenuItem value="Intense">Intense</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div>
                    <p className="font-normal text-[13px] text-gray-400 mb-1">When did you start experiencing the symptoms?</p>
                    <FormControl sx={{ mb: 1, minWidth: 120 }}>
                        {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={expect}
                            // label="Heala"
                            onChange={(e) => setExpect(e.target.value)}
                        >
                            <MenuItem value="This week">This week</MenuItem>
                            <MenuItem value="Last week">Last week</MenuItem>
                            <MenuItem value="One month ago">One month ago</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                
            </div>
            <div className='mt-12 doctor-form'>
                <h3 className="font-medium text-[18px]">Booking Preference</h3>
                <p className="font-normal text-[13px]">Pick a suitable time and communication channel for your consultation</p>

                <div className="mt-6 mid:space-x-4 grid grid-cols-2 space-x-4">
                    <Input
                        type="text"
                        label="First Name"
                        placeholder="First Name"
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        errormessage={firstNameError}
                    />
                    <Input
                        type="text"
                        label="Last Name"
                        placeholder="Last Name"
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        errormessage={lastNameError}
                    />
                </div>

                <div className='mt-6'>
                    <Input
                        type="email"
                        label="Email Address"
                        placeholder="Email Address"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        errormessage={emailError}
                    />
                </div>

                <div className="space-x-4 mt-6">
                    <PhoneDropdown country='ng' setPhone={setPhone} phone={phone} errormessage={phoneError} seterrormessage={setPhoneError} />
                   
                </div>

                <div className="space-x-4 mt-6">
                    <div className='w-full'>
                        <p className="font-normal text-[13px] text-gray-400 mb-1">Gender</p>
                        <FormControl sx={{ mb: 1, minWidth: 120 }}>
                            {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                className={classes.error}
                            >
                                <MenuItem value='male'>Male</MenuItem>
                                <MenuItem value='female'>Female</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className='mt-6' onClick={handleOpen}>
                    <p className='font-normal text-[13px] text-gray-400 mb-1'> Select a date</p>
                    <FormControl sx={{ mb: 1, minWidth: 120 }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                // sx={{width: '100%'}}
                                open={isOpen}
                                onOpen={handleOpen}
                                onClose={handleClose}
                                defaultValue={dayjs()}
                                views={['day']}
                                disablePast
                                onChange={handlegetDoctors}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>

                <div>
                    <p className="font-normal text-[13px] text-gray-400 mb-1">Select a time</p>
                    <FormControl sx={{ mb: 1, minWidth: 120 }}>
                        {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-helper-label"
                            // id="demo-simple-select-helper"
                            value={time}
                            // label="Heala"
                            onChange={(e) => setTime(e.target.value)}
                        >
                            {selectedSlot.length > 0?selectedSlot.map((t) => (
                            <MenuItem key={t._id} value={t.start}>{t.start} (GMT+1)</MenuItem>
                        )): <MenuItem>No available slot</MenuItem> }
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div className='mt-6'>
                <p className="font-normal text-[13px] text-gray-400 mb-1">Communication Channel</p>
                <FormControl sx={{ mb: 1, minWidth: 120 }}>
                    {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                    <Select
                        className={classes.error}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={channel}
                        // label="Heala"
                        onChange={(e) => setChannel(e.target.value)}
                    >
                        <MenuItem value="chat">Chat</MenuItem>
                        <MenuItem value="video">Video</MenuItem>
                        <MenuItem value="voice">Voice</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='mt-6'>
                <p className="font-normal text-[13px] text-gray-400 mb-1">Please share any other information related to your symptom</p>
                <FormControl sx={{ mb: 1, minWidth: 120 }}>
                    <TextareaAutosize

                    id="outlined-basic" label="" variant="outlined"
                    value={aob} onChange={(e) => setAob(e.target.value)}
                        aria-label="minimum height"
                        minRows={3}
                        placeholder=""
                        style={{ width: '100%', fontSize: '16px', padding: '8px', outline: 'none', border:"1px solid #c4c4c4",     borderRadius: "8px"
                    }}
                    />
                </FormControl>
            </div>

            <div className='flex-1 w-full flex items-end mt-12 gap-4'>
                <Button
                    variant='outlined'
                    onClick={() => {
                        handleReset();
                        navigate('/');
                    }}
                    className={classes.cancelButton}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    type='submit'
                    className={classes.submitButton}
                >
                    {loading ?
                        (<CircularProgress
                            style={{ width: 20, height: 20, color: "#fff" }}
                        />)
                        : 'Book Appointment'}
                </Button>
            </div>
        </form>
    );
}