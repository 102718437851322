import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { parse, format } from 'date-fns';

export const addOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) {
    return day + 'th';
  }
  switch (day % 10) {
    case 1:
      return day + 'st';
    case 2:
      return day + 'nd';
    case 3:
      return day + 'rd';
    default:
      return day + 'th';
  }
}

export const stringAvatar = (firstName, lastName) => {
  const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  return { children: initials };
};

// export const formatDateTime = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const formattedDate = addOrdinalSuffix(day) + ' ' + date.toLocaleDateString("en-US", { month: 'long', year: 'numeric' });
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     const period = hours >= 12 ? 'pm' : 'am';
//     const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
//     const formattedMinutes = minutes.toString().padStart(2, '0');
//     const formattedTime = formattedHours + ':' + formattedMinutes + period;

//     return {
//         formattedDate,
//         formattedTime
//     };
// }

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);

  const nigeriaOptions = { timeZone: 'Africa/Lagos' };

  const day = date.toLocaleDateString("en-US", { day: 'numeric', timeZone: 'UTC' });
  const formattedDate = addOrdinalSuffix(parseInt(day)) + ' ' + date.toLocaleDateString("en-US", { month: 'long', year: 'numeric', timeZone: 'UTC' });

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedTime = formattedHours + ':' + formattedMinutes + ampm;

  console.log(formattedDate,
    formattedTime,'formattedDate-formattedTime',dateString)

  return {
    formattedDate,
    formattedTime
  };
};




export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return formattedDate;
}

export const convertToISOString = (selectedDate, selectedTime) => {
  const date = dayjs(selectedDate).format('YYYY-MM-DD');
  const time = dayjs(selectedTime, 'HH:mm').format('HH:mm:ss');
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
  const timezoneOffsetMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
  const timezoneSign = timezoneOffset >= 0 ? '-' : '+';
  const timezone = `${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
  const isoString = `${date}T${time}${timezone}`;

  return isoString;
};

export const statusStyles = {
  Attended: 'bg-status-150 text-status-100 border-status-100 border',
  Completed: 'bg-status-150 text-status-100 border-status-100 border',
  Rescheduled: 'bg-status-350 text-status-300 border-status-300 border',
  Scheduled: 'bg-status-450 text-status-400 border-status-400 border',
  Accepted: 'bg-status-150 text-status-100 border-status-100 border',
  Ongoing: 'bg-status-450 text-status-400 border-status-400 border',
  Pending: 'bg-status-450 text-status-400 border-status-400 border',
  Missed: 'bg-status-250 text-status-200 border-status-200 border',
  Failed: 'bg-status-250 text-status-200 border-status-200 border',
  Cancelled: 'bg-status-250 text-status-200 border-status-200 border',
  Declined: 'bg-status-250 text-status-200 border-status-200 border',
}

export const parseInteger = (value) => {
  const parsedValue = parseInt(value, 10);
  return isNaN(parsedValue) ? null : parsedValue;
};

export const convertFirstLetterToUpperCase = (word) => {
  const capitalizedWord = word?.charAt(0)?.toUpperCase() + word?.slice(1);
  return capitalizedWord;
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const convertToMinutes = (time) => {
  const seconds = time;
  const minutes = Math.round(seconds / 60);
  return minutes;
}

export const convertToYear = (date) => {
  const originalDate = new Date(date);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export const formatDateWithTime = (date, time) => {
  const dateWithoutTime = date?.split(" ")[0] + " " + date?.split(" ")[1] + " " + date?.split(" ")[2] + " " + date?.split(" ")[3];
  const newDateStr = dateWithoutTime + " " + time + " GMT+0000 (Coordinated Universal Time)";
  const newDate = new Date(newDateStr);
  const formattedDate = newDate.toISOString();
  return formattedDate;
}

export const formatDateTime2 = (date, time) => {
  return `${date}T${time}:00.000+00:00`;
}

export const getAvailableTimeSlot = (availableSlots, currentDateStr) => {
  // console.log(currentDateStr,"currentDateStrcurrentDateStr")
  const currentDate = currentDateStr;
  const parts = currentDate.split(" ");
  const hour = parts[4].split('').splice(0, 2).join('')

  const oneHourAheadUtc = Number(hour) + Number('02');

  const filteredSlots = availableSlots?.filter((slot) => {
    let hourSlot = slot.start?.split('').splice(0, 2).join('');
    let available = slot.available
    // console.log(available,"currentDateStrcurrentDateStr")

    return (
      Number(hourSlot) > Number(hour) &&
      Number(hourSlot) > Number(oneHourAheadUtc) &&
      available == true
    );
  });

  return filteredSlots;
};

export const numberWithCommas = (number) => {
  return number?.toLocaleString('en-US');
};