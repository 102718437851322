import React, { useState } from 'react';
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import Selectfilter from './selectfilter';
import { convertFirstLetterToUpperCase } from '../helperFunctions';

export default function Filter(
  {
    setOpen, 
    filter, 
    setChip, 
    chipData, 
    setChipData, 
    filteredDoctor,
    setChipTest, 
    chipDataTest, 
    setChipDataTest, 
    filteredDoctorTest,
    setFilterCDoctor,
    setFilterCChannel,
    setFilterCStatus,
    setFilterTDoctor,
    setFilterTTest,
    filteredTest
  }
) {
  const [doctor, setDoctor] = useState('');
  const [channel, setChannel] = useState('');
  const [status, setStatus] = useState('');
  const [doctorTest, setDoctorTest] = useState('');
  const [test, setTest] = useState('');
  const [statusTest, setStatusTest] = useState('');
  const error = sessionStorage.getItem("network");
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);

    if (filter === 'consult') {
      setDoctor('');
      setChannel('');
      setStatus('');
      
      if (!doctor && !channel && !status) {
        setChipData([]);
        setChip(false);
        sessionStorage.removeItem('chipData');
        setFilterCDoctor('');
        setFilterCChannel('');
        setFilterCStatus('');
        return;
      }
      
      const newData = [...chipData];
      
      if (doctor) {
        const selectedDoctor = filteredDoctor.find((f) => `${f.lastName} ${f.firstName}` === doctor);
        setFilterCDoctor(selectedDoctor?._id);
        if (!newData.find((chip) => chip.doctor === selectedDoctor?._id)) {
          newData.push({ doctor: selectedDoctor?._id, name: doctor });
        }
      } else {
        const doctorInStorage = newData.find((chip) => chip.doctor);
        if (doctorInStorage) {
          setFilterCDoctor(doctorInStorage.doctor);
        } else {
          setFilterCDoctor('');
        }
      } 

      if (channel) {
        setFilterCChannel(channel);
        if (!newData.find((chip) => chip.channel === channel)) {
          newData.push({ channel, name: channel });
        }
      } else {
        const channelInStorage = newData.find((chip) => chip.channel);
        if (channelInStorage) {
          setFilterCChannel(channelInStorage.channel);
        } else {
          setFilterCChannel('');
        }
      }

      if (status) {
        setFilterCStatus(status);
        if (!newData.find((chip) => chip.status === status)) {
          newData.push({ status, name: convertFirstLetterToUpperCase(status) });
        }
      } else {
        const statusInStorage = newData.find((chip) => chip.status);
        if (statusInStorage) {
          setFilterCStatus(statusInStorage.status);
        } else {
          setFilterCStatus('');
        }
      }  

      setChipData(newData);
      sessionStorage.setItem('chipData', JSON.stringify(newData));

      if (error === 'TypeError: Failed to fetch') {
        setChipData([]);
        setChip(false);
        sessionStorage.removeItem('chipData');
        sessionStorage.removeItem('network');
      }
    }

    if (filter === 'test') {
      setDoctorTest('');
      setTest('');
      
      if (!doctorTest && !test) {
        setChipDataTest([]);
        setChipTest(false);
        sessionStorage.removeItem('chipTestData');
        setFilterTDoctor('');
        setFilterTTest('');
        return;
      }
      
      const newData = [...chipDataTest];

      if (doctorTest) {
        const selectedDoctor = filteredDoctorTest.find((f) => `${f.lastName} ${f.firstName}` === doctorTest);
        setFilterTDoctor(selectedDoctor?._id);
        if (!newData.find((chip) => chip.doctor === selectedDoctor?._id)) {
          newData.push({ doctor: selectedDoctor?._id, name: doctorTest });
        }
      } else {
        const doctorInStorage = newData.find((chip) => chip.doctor);
        if (doctorInStorage) {
          setFilterTDoctor(doctorInStorage.doctor);
        } else {
          setFilterTDoctor('');
        }
      } 

      if (test) {
        console.log(test, 'test')
        setFilterTTest(test);
        if (!newData.find((chip) => chip.test === test)) {
          newData.push({ test, name: convertFirstLetterToUpperCase(test) });
        }
      } else {
        const testInStorage = newData.find((chip) => chip.test);
        if (testInStorage) {
          setFilterTTest(testInStorage.test);
        } else {
          setFilterTTest('');
        }
      }

      setChipDataTest(newData);
      sessionStorage.setItem('chipTestData', JSON.stringify(newData));

      if (error === 'TypeError: Failed to fetch') {
        setChipDataTest([]);
        setChipTest(false);
        sessionStorage.removeItem('chipTestData');
        sessionStorage.removeItem('network');
      }
    }
  }

  return (
    <div className='flex flex-col font-euclid h-full my-5 mx-7'>
        <div className="cursor-pointer flex w-full justify-between items-center">
          <h3 className='font-medium text-[25px]'>Filters</h3>
          <Exit onClick={() => setOpen(false)} />
        </div>
        <div className='mt-6 flex-1'>
          <Selectfilter 
            filter={filter}
            doctor={doctor}
            doctorTest={doctorTest}
            filteredDoctor={filteredDoctor}
            filteredDoctorTest={filteredDoctorTest}
            status={status}
            filteredTest={filteredTest}
            test={test}
            setTest={setTest}
            statusTest={statusTest}
            channel={channel}
            setDoctor={setDoctor}
            setDoctorTest={setDoctorTest}
            setChannel={setChannel}
            setStatus={setStatus}
            setStatusTest={setStatusTest}
            handleSubmit={handleSubmit} 
          />
        </div>
    </div>
  )
}
