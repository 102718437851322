import React from 'react'
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import { Avatar } from '@mui/material';
import Selectconsult from './selectconsult';
import { stringAvatar } from '../helperFunctions';

export default function Consultpref({setPref, setOpen, rowData}) {
    return (
        <div className='flex flex-col font-euclid h-full mt-10 mx-6'>
            <div className="cursor-pointer flex w-full justify-between items-center">
                <h3 className='font-medium text-[25px]'>Follow-up Consultation</h3>
                <Exit onClick={() => {
                    setPref(false);
                    setOpen(false);
                }}/>
            </div>
            <div className='mt-6'>
                <div className='flex gap-3'>
                    <div className='flex self-center'>
                        {rowData?.doctorData?.picture ? (
                            <img src={rowData?.doctorData?.picture} alt='doc' className="w-16 h-16 rounded-full"/>
                        ) : (
                            <Avatar {...stringAvatar(rowData?.doctorData?.lastName, rowData?.doctorData?.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/>    
                        )}
                    </div>
                    <div>
                        <h3 className="font-medium text-[25px]">{rowData?.doctorData?.lastName} {rowData?.doctorData?.firstName}</h3>
                        <p className="font-normal text-[16px]"> {rowData?.doctorData?.specialization  == 'Nil' ? rowData?.doctorData?.cadre : rowData?.doctorData?.specialization}</p>
                        {rowData?.doctorData?.rating && (
                            <div className='flex items-center gap-1'>
                                {[...Array(Math.floor(rowData?.doctorData?.rating))].map((_, index) => (
                                    <Star key={index} />
                                ))}
                                <p className="text-[11px]">{Number.isInteger(rowData?.doctorData?.rating) ? `${rowData?.doctorData?.rating}.0` : rowData?.doctorData?.rating}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='mt-10 h-full'>
                <h3 className="font-medium text-[25px]">Booking Preference</h3>
                <p>Pick a suitable time for your consultation.</p>
                <Selectconsult setPref={setPref} setOpen={setOpen} data={rowData}/>
            </div>
        </div>
    )
}
