import { makeStyles } from "@mui/styles";

export const useSchedulePageStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#FAFAFA",
        width: "100%",
        minHeight: "100vh",
        overFlow:"hidden",
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme?.breakpoints?.down(600)]: {
            alignItems: "flex-start",
        },
    },
    content: {
        position: "relative", 
        width: "75%", 
        height: "auto", 
        background: "#FFFFFF",
        border: "1px solid rgba(128, 128, 128, 0.25)",
        margin: "30px 0px", 
        borderRadius: "24px", 
        display:"block",
        [theme?.breakpoints?.down(901)]: {
            width: "95%",
            margin: "20px 0px"
        },
    },
    container: {
        flexDirection: "column",
        display: "flex",
        overflow: "hidden",
        position: "relative",
    },
    head: {
        display: "flex",
        padding: "20px 32px !important",
        justifyContent:"space-between",
        alignItems: "center",
        [theme?.breakpoints?.down(901)]: {
            padding: "20px !important"
        },
    },
    logo : {
        height: "47px",
        [theme?.breakpoints?.down(901)]: {
            height: "35px"
        },
    },
    exit: {
        display: "flex",
        alignSelf: "end",
        cursor:"pointer"
    },
    mid: {
        padding: "20px 32px 20px 32px",
        textAlign: "center",
        fontFamily: "Euclid Circular A",
        [theme?.breakpoints?.down(901)]: {
            padding: "8px 32px"
        },
    },
    h1: {
        marginBottom: "10px",
        fontWeight: 500,
        color: "#2C2C2C",
        fontStyle: "normal",
        fontSize: "25px",
        lineHeight: "30px",
        [theme?.breakpoints?.down(901)]: {
            fontSize: "18px",
            marginBottom: "5px",
            marginTop: "-1%"

        },
    },
    h2: {
        color:"#6C6C6C",
        fontSize: "15px",
        fontWeight: 500,
        [theme?.breakpoints?.down(901)]: {
            fontSize: "12px"
        },
    },
    foot: {
        padding: "0px 150px 50px 150px",
        [theme?.breakpoints?.down(901)]: {
            padding: "40px 30px"
        },
    },
    footer: {
        padding: "48px 150px",
    },
    button: {
        background: "#3E5EA9 !important",
        borderRadius: ` 12px !important`,
        padding: '10px 45px !important',
        fontFamily: "Euclid Circular A !important",
        textTransform: "capitalize !important",
        fontWeight: "500 !important",
        fontSize: "18px !important",
        width: "auto",
        height: "auto",
        alignSelf: "center",
        [theme?.breakpoints?.down(901)]: {
            width: "auto",
            height: "auto",
        },
   },
   button1: {
    background: "#3E5EA9 !important",
    borderRadius: ` 12px !important`,
    padding: '10px 60px !important',
    fontFamily: "Euclid Circular A !important",
    textTransform: "capitalize !important",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    width: "auto",
    height: "auto",
    alignSelf: "center",
    [theme?.breakpoints?.down(901)]: {
        width: "auto",
        height: "auto",
    },
},
}))