import React, { useState, useEffect } from 'react'
import { useMutation, useQuery, ApolloError } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RESCHEDULE_CONSULTATION, GET_SERVER_TIME, GET_DOCTOR_AVAILIBILITY_FOR_DATE } from '../../api/graphQL/query';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextareaAutosize } from '@mui/material';
import useAlert from '../../hooks/useAlert';
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import Avatar from '../../assets/images/doc.png';
import { formatDateWithTime, getAvailableTimeSlot } from '../helperFunctions';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
    cancelButton: {
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        borderColor: "#3E5EA9 !important",
        color: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%'
    },
    submitButton: {
        padding: "18px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%'
    },
}));

export default function Selectable({ setDraw, modal, time }) {
    const classes = useStyles();
    const { displayAlert } = useAlert();
    const authToken = localStorage.getItem('authToken');
    const docId = modal?.doctorData?._id;
    const id = modal?._id;
    const date = modal?.time;
    const todayDate = dayjs().format('YYYY-MM-DD');

    const [times, setTimes] = useState("");
    const [availableTime, setAvailableTime] = useState([]);
    const [reason, setReason] = useState("");
    const [selectedDate, setSelectedDate] = useState(todayDate);
    
    const { error:docErr, data:docData, loading:docLoad } = useQuery(GET_DOCTOR_AVAILIBILITY_FOR_DATE, {
        skip: !docId,
        variables: { id: docId, date: selectedDate },
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });

    useEffect(() => {
        if (docId) {
            getDoctorsTimesByDate();
        }
        return;
    }, [docData, selectedDate])

    // const formattedDate = formatDateWithTime(date, times);
    const { error:serErr, data:serData, loading:serLoad } = useQuery(GET_SERVER_TIME);

    const getServerTime = serData?.getServerTime?.timeLagosJsDate;

    const currentDateL = getServerTime?.split('').splice(0, 15).join('');
    const currentDate = getServerTime
    var inputDate = selectedDate
    var dateComponents = inputDate.split("-");

    var year = dateComponents[0];
    var month = parseInt(dateComponents[1]) - 1; // JavaScript uses zero-based months
    var day = dateComponents[2];

    var months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var dateObj = new Date(year, month, day);
    var formattedDateC = dateObj.toString().substr(0, 3) + " " + months[month] + " " + day + " " + year;

    // console.log(selectedDate, "abc")

    const selectedSlot = currentDateL == formattedDateC
    ? getAvailableTimeSlot(availableTime, currentDate)
    : availableTime;

    const [rescheduleMutation, { loading, error }] = useMutation(RESCHEDULE_CONSULTATION);

    const rescheduleConsultation = async (e) => {
        e.preventDefault();
        // const formattedDate = formatDateWithTime(selectedDate);
        // Given date and time

        console.log(selectedDate,"selectedDate")
        var givenDate = selectedDate
        var givenTime = times

        // Convert to Date object
        var givenDatetime = new Date(givenDate + "T" + givenTime);

        // Subtract a day and adjust time for UTC+7
        givenDatetime.setUTCHours(givenDatetime.getUTCHours());

        // Format the adjusted datetime in ISO 8601 with UTC
        var formattedResult = givenDatetime.toISOString();
        try {
            const rescheduleResponse = await rescheduleMutation({
                variables: {
                    id: id,
                    time: formattedResult,
                    doctor: docId,
                    reason: reason
                },

                context: {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            });
            displayAlert('success', 'Consultation rescheduled successfully');
            setDraw(false);
        } catch (error) {
            if (error instanceof ApolloError && error.message.includes("You have exceeded your consultation limit")) {
                displayAlert('warning', 'You have exceeded your consultation limit');
            }
            displayAlert('error', 'Error rescheduling consultation');
            console.error(error);
        }
    }

    const handlegetDoctors = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        // setDate(formattedDate);
        getDoctorsTimesByDate();
    };

    const getDoctorsTimesByDate = () => {
        setAvailableTime(docData?.getDoctorAvailabilityForDate?.times || []);
    };

    return (
        <div className='flex flex-col font-euclid h-full my-12 mx-7'>
            <div className="cursor-pointer flex w-full justify-between items-center">
                <h3 className='font-medium text-[25px]'>Reschedule Appointments</h3>
                <Exit onClick={() => setDraw(false)} />
            </div>
            <div className='mt-6'>
                <div className='flex gap-3'>
                    <div className='flex self-center'>
                        <img src={modal?.doctorData?.picture || Avatar} alt='doctor' className="w-16 h-16 rounded-full" />
                    </div>
                    <div>
                        <h3 className="font-medium text-[25px]">{modal?.doctorData?.lastName} {modal?.doctorData?.firstName}</h3>
                        <p className="font-normal text-[16px]">{modal?.doctorData?.specialization}</p>
                        {modal?.doctorData?.rating && (
                            <div className='flex items-center gap-1'>
                                {[...Array(Math.floor(modal?.doctorData?.rating))].map((_, index) => (
                                    <Star key={index} />
                                ))}
                                <p className="text-[11px]">{Number.isInteger(modal?.doctorData?.rating) ? `${modal?.doctorData?.rating}.0` : modal?.doctorData?.rating}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='mt-6 flex-1'>
                <form className='flex flex-col h-full gap-6' onSubmit={rescheduleConsultation}>
                    <div>
                        <p className='text-gray-400'>Select a date</p>
                        <FormControl sx={{ marginTop: '5px', minWidth: 120 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar
                                    defaultValue={dayjs()}
                                    views={['day']}
                                    disablePast
                                    onChange={handlegetDoctors}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>

                    <div>
                        <p className='text-gray-400'>Select a time</p>
                        <FormControl sx={{ marginTop: '5px', minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={times}
                                // label="Heala"
                                onChange={(e) => setTimes(e.target.value)}
                            >
                                {selectedSlot.length > 0 ? selectedSlot.map(t => (
                                    <MenuItem key={t._id} value={t.start}>{t.start} (GMT+1)</MenuItem>
                                )) : <MenuItem>No available slot</MenuItem>}
                            </Select>
                            {/* {selectedSlot.length > 0?selectedSlot.map((t) => (
                                <MenuItem key={t._id} value={t.start}>{t.start} (GMT+1)</MenuItem> */}
                        </FormControl>
                    </div>
                    <div className="custom-fieldset">
                        <p className='text-gray-400 '>Reason for rescheduling</p>
                        <FormControl sx={{ mt: '1px', minWidth: 120 }}>
                            <TextareaAutosize

                                id="outlined-basic" label="" variant="outlined"
                                value={reason} onChange={(e) => setReason(e.target.value)}
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="I have another appointment"
                                style={{
                                    width: '100%', fontSize: '16px', padding: '8px', outline: 'none', border: "1px solid #c4c4c4", borderRadius: "8px"
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className='flex-1 w-full flex items-end mb-10 gap-4 btn_flex'>
                        <Button
                            variant='outlined'
                            onClick={() => setDraw(false)}
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            className={classes.submitButton}
                            type='submit'
                        >
                            {loading ?
                                (<CircularProgress
                                    style={{ width: 20, height: 20, color: "#fff" }}
                                />)
                                :
                                'Book Appointment'
                            }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
