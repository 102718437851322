import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

export const ResetSuccessful = () => {
    const navigate = useNavigate();
    
    return (
        <div className="w-full">
            <h1 className="font-semibold text-black text-[40px] leading-[50.72px] m-0">
                Password Reset Successful!
            </h1>
            <p className="text-base leading-[20.29px] m-0 mt-2">
                Your new password has been created. Login into your dashbaord
            </p>   
            <div className='mt-12'>
                <Button 
                    fullWidth 
                    sx={{
                        textTransform: "capitalize",
                        fontSize: "13px",
                        fontFamily: "Euclid Circular A" ,
                        borderRadius: "8px",
                        backgroundColor: "#3E5EA9",
                        color: "#FFF",
                        padding: "12px 24px",
                        "&": {
                            backgroundColor: "#3E5EA9 !important",
                        },
                    }}
                    onClick={() => navigate('/start')} 
                >
                    Login
                </Button>
            </div>   
        </div>
    )
}
