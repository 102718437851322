import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export const PhoneDropdown = ({country, phone, setPhone, errormessage, seterrormessage}) => {
    return (
        <div className="flex flex-col phone">
            <label className="block text-base leading-[20.29px] text-gray-400 mb-[5px]">Phone</label>
            <PhoneInput
                country={country}
                enableSearch={true}
                value={phone} 
                onChange={newPhone => {
                    setPhone(newPhone)
                    seterrormessage('')
                }}
                placeholder="Phone Number"
            />


            {errormessage && <p className="error__msg mt-[1px]">{errormessage}</p>}
        </div>
    )
}