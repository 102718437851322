import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const useDynamicTimeDifferenceFromNow = (targetDayJSTime, updateIntervalSeconds = 60) => {
  const [timeDifference, setTimeDifference] = useState(0);

  useEffect(() => {
    const calculateTimeDifference = () => {
      const currentTime = dayjs();
      const differenceInMinutes = targetDayJSTime.diff(currentTime, 'minute');
      setTimeDifference(differenceInMinutes);
    };

    calculateTimeDifference();

    const intervalId = setInterval(calculateTimeDifference, updateIntervalSeconds * 1000);

    return () => clearInterval(intervalId);
  }, [targetDayJSTime, updateIntervalSeconds]);

  return timeDifference;
};

export default useDynamicTimeDifferenceFromNow;
