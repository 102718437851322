import { makeStyles } from "@mui/styles";

export const useHomeStyles = makeStyles((theme) => ({
     root: {
          width: "100%",
          height: "auto",
          overFlow:"hidden",
          margin: 0,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [theme?.breakpoints?.down(600)]: {
               alignItems: "flex-start",
          },
     },
}));
