import React from "react";
import "./home.css";


const StartConsultation = () => {
  return (
    <div className="landing_div">
      <div className="info_container">
        <div className="logo_div">
          <img src={"https://consultation.heala.io/static/media/Logo.a13506ceb486836a4fa84c9a0e1c6a7c.svg"} alt="HEALA Logo" />
        </div>
        <h1 className="info_heading">Online Consultation</h1>
        <p className="info_para">
          {" "}
          Visit{" "}
          <a className="info_link" href="https://developer.heala.io/">
            developer.heala.io{" "}
          </a>{" "}
          to integrate our consultation API or contact us if you need help with
          integration.
        </p>
      </div>
    </div>
  );
};

export default StartConsultation;
