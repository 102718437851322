import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DatePickerComp from '../../components/date/date';
import { ReactComponent as Exit } from '../../assets/svgs/exit.svg';
import { ReactComponent as Empty } from '../../assets/svgs/error.svg';
import Doctors from '../../components/doctors/doctors';
import { useNavigate } from 'react-router-dom';
import { ConsultationContext } from '../../context/consultationContext';
import { ProfileContext } from '../../context/profileContext';
import { setContext } from '@apollo/client/link/context';
import Loader from '../../components/Loader';
import { useQuery, ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { FIND_PROFILES } from '../../api/graphQL/query';
import { useFetchAPI } from "../../api/axios/axios";
import { tr } from 'date-fns/locale';




export default function BookConsultation() {
    const navigate = useNavigate();
    const { fetchConsultation } = useContext(ConsultationContext);
    const { fetchProfile, profile } = useContext(ProfileContext);
    const todayDate = dayjs().format('YYYY-MM-DD');
    const [doctorData, setDoctorData] = useState([]);
    const [doctors, setDoctors] = useState(null);
    const [doctorLoading, setDoctorLoading] = useState(true);


    const providerId = localStorage.getItem("providerId");
    const [selectedDate, setSelectedDate] = useState(todayDate);
    const authId = localStorage.getItem('authId');
    const authToken = localStorage.getItem('authToken');
    const { GET_AVAILABLE_DOCTORS_BY_DATE } = useFetchAPI();

    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_API_BASE_URL,
    });

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: authToken ? `Bearer ${authToken}` : '',
            },
        };
    });
    
    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });
    
    const { loading: profileLoading, error: profileError, data: profileData } = useQuery(FIND_PROFILES, {
        skip: !authToken && !authId,
        variables: { dociId: authId },
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });
    
    // const { loading: doctorLoading, error: doctorError, data: doctorData } = useQuery(GET_AVAILABLE_DOCTORS_BY_DATE, {
    //     skip: !authToken && !providerId ,
    //     variables: { id: providerId, date: selectedDate },
    //     context: {
    //         headers: {
    //             Authorization: `Bearer ${authToken}`
    //         }
    //     }
    // });
   
    let key = localStorage.getItem("iskey");

    
    const getDoctors = async(date) => {
        setSelectedDate(date)
        const res = await GET_AVAILABLE_DOCTORS_BY_DATE(date,providerId);
        setDoctorLoading(false)

       setDoctorData(res.data.data)
       setDoctors(res.data.data)
    };
    
    useEffect(() => {
        getDoctors(selectedDate)
    }, [])



    const handleExit = () => {
        fetchConsultation();
        navigate('/start');
    }

    return (
        <ApolloProvider client={client}>
            {profileLoading ? <Loader type='fullPage'/> : (
                <div className="min-h-screen flex flex-col font-euclid my-7 mx-5 md:mx-10 ">
                  {key ? '':  <div className="cursor-pointer flex w-full justify-end" onClick={handleExit}>
                        <Exit  />
                    </div>}
                    <h1 className="font-medium md:font-semibold text-[25px] md:text-[40px] text-[#000000] text-center " style={key? {marginTop:'5%' }: {}}>Select a Date to Schedule an Appointment with a Doctor</h1>
                    <div className="mt-6 md:mt-10 flex flex-col">
                        <div className="w-full md:w-10/12 laptop:w-[32rem] self-center">
                            <h4 className="font-normal text-gray-400 mb-1">Consultation Date</h4>
                            <DatePickerComp getDoctors={getDoctors}/>
                        </div>
                        {doctorLoading && <Loader type='fullPage'/>}
                        {doctors ? (
                            <div className="mt-12 sm:mt-16 w-full md:w-10/12 self-center">
                                {key ? <h4 className="font-normal text-gray-400 mb-1">Available Doctors</h4> :  <h2 className="font-medium text-[25px] text-center">Available Doctors</h2>}
                                <Doctors doctors={doctors} selectedDate={selectedDate} />
                            </div>
                        ) : (
                            <div className='flex justify-center h-full w-full mt-[100px]'>
                                <div className='flex flex-col items-center justify-center h-full'>
                                    <Empty />
                                    <h3 className='-mt-6 text-[25px] font-medium font-euclid'>No available doctor on this day</h3>
                                    <p className='text-center font-euclid text-gray-400'>Kindly change the date</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ApolloProvider>
    )
}
