import { createContext, useState } from "react";

export const TestContext = createContext();

export const TestProvider = ({children}) => {
    const [test, setTest] = useState(null);
    const [test2, setTest2] = useState(null);
        
    const fetchTest = () => {
        const storedTest = sessionStorage.getItem("test");
        if (storedTest !== undefined && storedTest !== null && storedTest !== "undefined") {
            try {
                const parsedTest = JSON.parse(storedTest);
                setTest(parsedTest.getReferrals);
            } catch (error) {
                console.error("Error parsing stored test:", error);
            }
        } else {
            setTest(null);
        }  
    }
    
    const fetchTest2 = () => {
        const storedTest = sessionStorage.getItem("test2");
        if (storedTest !== undefined && storedTest !== null && storedTest !== "undefined") {
            try {
                const parsedTest = JSON.parse(storedTest);
                setTest2(parsedTest.getReferrals);
            } catch (error) {
                console.error("Error parsing stored test:", error);
            }
        } else {
            setTest2(null);
        }  
    }

    return (
        <TestContext.Provider value={{test, fetchTest, test2, fetchTest2}}>
            {children}
        </TestContext.Provider>
    )
}