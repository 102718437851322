import React, { useState, useContext} from 'react'

import { ReactComponent as Search } from '../../assets/svgs/search.svg';
import { ReactComponent as Menus } from '../../assets/svgs/menu.svg';
import { ReactComponent as Empty } from '../../assets/svgs/error.svg';

import Drawers from '../drawers';
import Filter from '../filter/filter';
import Tabledashboard from '../table/table';
import Menu from '../menu/menu';
import { PrescriptionContext } from '../../context/prescriptionContext';

export default function Prescribe({setMenu, menu, activeItem, setActiveItem, profileLoading, openProfile, setOpenProfile, prescriptionLoading}) {
  const { prescription } = useContext(PrescriptionContext);
  const [open, setOpen] = useState(false);

  const tableHead = [ 'Drug Name','Doctor Name', 'Date', 'Action']
  // const tableHead = ['Prescription ID', 'Doctor Name', 'Date', 'Status', 'Action']
  const prescribe = 'prescribe';

  return (
    <>
      <div className='flex items-center gap-4'>
        <Menus className='desktop:hidden' onClick={() => setMenu(!menu)} />
        <h3 className='font-medium text-[25px]'>Prescriptions</h3>
      </div>
      {prescription?.data?.length === 0 ? (
        <div className='flex justify-center h-full w-full'>
          <div className='flex flex-col items-center justify-center h-[91%] w-2/5'>
            <Empty />
            <h3 className='-mt-6 text-[25px] font-medium font-euclid'>No Prescriptions</h3>
            <p className='text-center font-euclid text-gray-400'>Prescriptions are automatically added when a doctor prescribes one.</p>
          </div>
        </div>
      ) : (
        <>
          <div className='mt-6 tab:mt-5 laptop:mt-8 mb-8 md:mb-6 flex flex-col tab:flex-row gap-6 tab:gap-4 tab:items-center'>
            {/* <div className='flex'>
              <Search className='absolute mx-6 my-3'/>
              <input placeholder='Search' className='focus:outline-none placeholder:text-gray-700 w-full tab:w-[362px] py-3 pr-6 pl-14 border rounded-lg border-gray-50'/>
            </div> */}
            {/* <p className='font-medium text-primary text-[13px] cursor-pointer hover:opacity-75 transition ease-out' onClick={() => setOpen(!open)}>
            
            </p> */}
          </div>

          <Tabledashboard profileLoading={profileLoading} tableHead={tableHead} index={prescribe} loading={prescriptionLoading}/>
      
          <Drawers openDrawer={open} setOpenDrawer={setOpen}>
            <Filter setOpen={setOpen} filter='prescribe'/>
          </Drawers>
        </>
      )}


      <Drawers openDrawer={menu} setOpenDrawer={setMenu} side="left" width='298px' minwidth='298px'>
        <div className='px-8 pt-10 h-full'>
          <Menu activeItem={activeItem} setActiveItem={setActiveItem} menu={menu} setMenu={setMenu} openProfile={openProfile} setOpenProfile={setOpenProfile}/>
        </div>
      </Drawers>
    </>
  )
}
