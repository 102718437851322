import { createContext, useState } from "react";

export const ProfileContext = createContext();

export const ProfileProvider = ({children}) => {
    const [profile, setProfile] = useState(null);
        
    const fetchProfile = () => {
        const storedProfile = localStorage.getItem("profile");
        if (storedProfile !== undefined && storedProfile !== null && storedProfile !== "undefined") {
            try {
                const parsedProfile = JSON.parse(storedProfile);
                setProfile(parsedProfile);
            } catch (error) {
                console.error("Error parsing stored profile:", error);
            }
        } else {
            setProfile(null);
        }  
    }

    return (
        <ProfileContext.Provider value={{profile, fetchProfile}}>
            {children}
        </ProfileContext.Provider>
    )
}