import React from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function DatePickerComp ({getDoctors}) {
  const handlegetDoctors = (value) => {
    const formattedDate = value.format('YYYY-MM-DD');
    getDoctors(formattedDate);
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker 
        defaultValue={dayjs()} 
        disablePast
        onChange={handlegetDoctors}
      />
    </LocalizationProvider>
  );
}