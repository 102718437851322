import * as Yup from "yup";

export const personalInfoValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required."),
  email: Yup.string().email("Invalid email").required("Email is required."),
  phoneNumber: Yup.number("Enter your Phone Number")
    .typeError(" Enter a valid phone number")
    .min(11, "min value is  11 digits")
    .required("Phone number is required"),
  gender: Yup.string().required("Gender is required."),
});

export const symptomsValidationSchema = Yup.object().shape({
  symptoms: Yup.array()
    .of(Yup.object().shape({ name: Yup.string().required() }))
    .min(1, "Select at least one symptoms")
    .typeError("Select a symptom")
    .required("Select a symptom."),
  discomfortLevel: Yup.string().required("Select a discomfort level."),
  description: Yup.string("Describe your sickness."),
  firstNotice: Yup.string().required("Select a first notice period."),
  contactMedium: Yup.string().required("Select a contact medium."),
});

export const consultationValidationSchema = Yup.object().shape({
  doctor: Yup.string().required("Select a doctor."),
  time: Yup.string().typeError("Select a date and time.").required("Choose a time and Date."),
}).concat(symptomsValidationSchema);

export const formValidationSchema = personalInfoValidationSchema.concat(
  consultationValidationSchema
);
