import React from 'react'
import { ReactComponent as Exit } from '../../assets/svgs/exit2.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import { convertFirstLetterToUpperCase, formatDateTime } from '../helperFunctions';
import { Avatar } from '@mui/material';
import { stringAvatar } from '../helperFunctions';

export default function Selectprescribe({setPrescribe, data}) {
    const { formattedDate, formattedTime } = formatDateTime(data?.patientData?.updatedAt);

    return (
        <div className='flex flex-col font-euclid h-full my-10'>
            <div className="cursor-pointer flex w-full justify-between items-center px-6">
                <h3 className='font-medium text-[25px]'>Prescription Summary</h3>
                <Exit onClick={() => setPrescribe(false)} />
            </div>
            <div className='mt-6 px-6'>
                <div className='flex gap-3'>
                    <div className='flex self-center'>
                    {data?.doctorData?.picture ? (
                        <img src={data?.doctorData?.picture} alt='doctor' className="w-16 h-16 rounded-full"/>
                    ) : (
                        data?.doctorData === null ?
                        <Avatar sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}>Nil</Avatar>
                    :
                        <Avatar {...stringAvatar(data?.doctorData?.lastName, data?.doctorData?.firstName)} sx={{fontSize:"15px", fontFamily: "Euclid Circular A"}}/>    
                    )}
                    </div>
                    <div>
                        <h3 className="font-medium text-[25px]">{data?.doctorData?.lastName} {data?.doctorData?.firstName}</h3>
                        <p className="font-normal text-[16px]"> {data?.doctorData?.specialization  == 'Nil' ? data?.doctorData?.cadre : data?.doctorData?.specialization}</p>
                        {data?.doctorData?.rating && (
                            <div className='flex items-center gap-1'>
                                {[...Array(Math.floor(data?.doctorData?.rating))].map((_, index) => (
                                    <Star key={index} />
                                ))}
                                <p className="text-[11px]">{Number.isInteger(data?.doctorData?.rating) ? `${data?.doctorData?.rating}.0` : data?.doctorData?.rating}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='mt-6 mb-10 px-6 flex flex-col gap-2'>
                <h5 className='font-medium'>Date & Time Issued</h5>
                <p>{formattedDate} at {formattedTime}</p>
                {/* <p className='px-2 text-center rounded-[10px] w-[102px] bg-status-450 text-status-400 border-status-400 border'>Ongoing</p> */}
            </div>
            <div className='w-full bg-secondary px-6 py-10 flex-1'>
                <h3 className='font-medium text-[25px]'>Wella Health</h3>
                <p>6 Ogunyemi Rd, Maroko 101233, Lagos, 08173162440</p>
                {/* <div className='mt-2 py-2 bg-status-300 text-white w-[158px] rounded-[100px]'>
                    <p className='text-center font-medium'>Delivery - Fulfilled</p>
                </div> */}
                {data?.drugs && (
                    <div className='mt-10'>
                        {data?.drugs?.map(d => (
                            <div className='mb-10'>
                                <div className='bg-white px-12 py-6 border border-gray-700 rounded-lg shadow-inner'>
                                    <h5 className='font-medium'>{d?.drugName}</h5>
                                    <p>{d?.dosageQuantity} tablet, {d?.dosageFrequency?.timing}x daily for {d?.dosageFrequency?.duration} day. {convertFirstLetterToUpperCase(d?.instructions)}.</p>
                                </div>
                                <h3 className='mt-5 font-medium text-[25px]'>Details</h3>
                                <div className='mt-4 flex justify-between'>
                                    <div>
                                        <h5 className='font-medium'>Route</h5>
                                        <p>{d?.route}</p>
                                    </div>
                                    <div>
                                        <h5 className='font-medium'>Drug Price</h5>
                                        <p>{d?.drugPrice}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
